// plan privileges
export const planAvailedData = [
    { data: "AI Tools" },
    { data: "AI Transcriber" },
    { data: "AI Chat" },
    { data: "AI Gallery" },
    { data: "VIP Check List" },
    { data: "Shine Ranker Page" },
];

// Current shinefy plans
export const SRPageConfig = ["Shinefy Diamond", "Shinefy Annual Diamond"];
export const checkListConfig = ["Shinefy Platinum", "Shinefy Diamond", "Shinefy Annual Platinum", "Shinefy Annual Diamond"];
export const allAccess = ["Shinefy Gold", "Shinefy Platinum", "Shinefy Diamond", "Shinefy Annual Gold", "Shinefy Annual Platinum", "Shinefy Annual Diamond"];
export const subsStatus = ["subscribed", "cancelled", "trial", "expiredSubscription"];
export const acceptedStatus = ["subscribed", "trial", "freeAccess"];