import React from "react";

const EmptyPage = (props) => {

    // props
    const { message } = props;

    return (
        <div className="flex items-center justify-center h-screen font-bold text-[1.5rem]">
            <p className="m-[2rem] lg:m-[20rem]">{message}</p>
        </div>
    );
};

export default EmptyPage;