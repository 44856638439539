// libraries
import React, { useContext } from "react";

// custom components
import UserContext from "../../components/UserContext";
import consoleLog from "../../functions/consoleLog";
import { acceptedStatus, checkListConfig } from "../../Configs/planData";

const CheckList = () => {

    // context method
    const {

        // Others
        subscriptionData,

    } = useContext(UserContext);

    // console log activity
    // consoleLog("subscriptionData", subscriptionData);

    return (
        <div>
            {/* {checkListConfig.includes(subscriptionData?.status?.item_name) && acceptedStatus.includes(subscriptionData?.message) ? ( */}
            {acceptedStatus.includes(subscriptionData?.message) ? (
                <iframe
                    className="w-[100%] h-screen"
                    src="https://docs.google.com/spreadsheets/d/1MSdGarFqPbQFIBVj3EuQ-GIUF7HRnANDkTIMV2WQCxU/edit?usp=sharing"
                    title="ShineRanker SEO Audit"
                />
            ) : (
                <div className="flex items-center justify-center h-screen font-bold text-[1.5rem]">
                    <p className="m-[2rem] lg:m-[20rem]">Available only to <span className="text-lime-400">{checkListConfig?.map(item => `${item}, `)}</span> subscribers.</p>
                </div>
            )}
        </div>
    );
};

export default CheckList;