// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Box from "@mui/material/Box";
import { Image } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import { throttle } from "lodash";

// custom components
import { fetchGeneratedVideos, getGeneratedVideos, handleCreateVideoThumbnail, taskStatus } from "../Tools/API/toolsAPI";
import UserContext from "../../../components/UserContext";
import consoleLog from "../../../functions/consoleLog";
import ViewMedia from "./ViewMedia";
import shinefy_robot from "../../../constant/images/ShinefyRobot.png";
import { getConfig, removeTaskId, saveTaskId } from "../Tools/API/configDBAPI";
import { ToastEffect } from "../../../functions/ToastEffect";
import shinefy_default_image_portrait from "../../../constant/images/default_image_display_portrait.png";
import shinefy_default_image_landscape from "../../../constant/images/default_image_display_landscape.png";
import { acceptedStatus, allAccess } from "../../../Configs/planData";

const VideoGallery = () => {

    const {
        // other context data
        userData,
        toolsDataStatus,
        refetchData,
        subscriptionData,

        // For gallery Videos
        videoData,
        setVideoData,
        getVideoData,
    } = useContext(UserContext);

    const email = userData?.UserInfo?.email;
    const username = userData?.UserInfo?.username;

    // userefs, navigates and use location
    const imageListRef = useRef(null);
    const videoRefs = useRef([]);
    const location = useLocation();
    const navigate = useNavigate();
    const modalMediaViewRef = useRef(null);

    // path name
    const { pathname } = location;

    // set the video count to be fetched
    const itemsPerPage = 30; // Display the first fetched videos including their status: processing, deleted, fail.

    // use states
    // const [videoData, setVideoData] = useState([]);
    // const [totalSumVideos, setTotalSumVideos] = useState(0);
    const [screenSize, setScreenSize] = useState({ isMobile: false, isSm: false, isMd: false, isLg: false, isXl: false, });
    const [showViewMediaMOdal, setShowViewMediaMOdal] = useState(false);
    const [viewMediaData, setViewMediaData] = useState([]);
    const [offset, setOffset] = useState(0);

    // refetch thumbnail data 
    useEffect(() => {
        const refetchImageData = () => {
            // Create a new array with the updated thumbnails
            const updatedData = videoData.map(item => {

                // sort data via task_id
                const imageStatus = toolsDataStatus?.find((status) =>
                    status?.tools_status?.task_id === item.task_id
                );

                // update thumbnail url
                if (item.task_id === imageStatus?.tools_status?.task_id) {
                    return { ...item, thumbnail: imageStatus?.tools_status?.message };
                }

                // return processed data
                return item;
            });

            // Update the state with the new array
            setVideoData(updatedData);
        };
        refetchImageData();
    }, [refetchData]);

    // infinite scroll function
    useEffect(() => {
        const handleScroll = throttle(async () => {
            if (imageListRef.current) {
                // const { scrollTop, scrollHeight, clientHeight } = imageListRef.current;
                // Check if the scroll position is at the bottom
                // For window scroll, compare window scroll positions to document body's height
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const scrollHeight = document.documentElement.scrollHeight;
                const clientHeight = window.innerHeight;

                if (scrollTop + clientHeight >= scrollHeight - 1) {

                    const newOffset = offset + itemsPerPage; // Use the current offset state
                    setOffset(newOffset); // Update the offset state

                    const requiredData = {
                        email: email,
                        start: itemsPerPage,
                        type: process.env.REACT_APP_VIDEO_GEN_ID,
                        offset: newOffset, // Use the updated offset
                        kind: "scroll"
                    };

                    getVideoData(requiredData);
                    // consoleLog('Reached the bottom of the ImageList', `${scrollTop}-${clientHeight}-${scrollHeight}`);
                }
            }
        }, 100);

        // Listen to scroll event on window
        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => window.removeEventListener('scroll', handleScroll);
    }, [offset]);

    // useeffect for fetching video data on load / reload
    useEffect(() => {
        getVideoData();
    }, []);

    // check screen size advance
    useEffect(() => {
        const checkScreenSize = () => {
            const width = window.innerWidth;
            setScreenSize({
                isMobile: width <= 375,
                isSm: width >= 375 && width < 768,
                isMd: width >= 768 && width < 1024,
                isLg: width >= 1024 && width < 1280,
                isXl: width >= 1280,
            });
        };

        // Check the screen size initially
        checkScreenSize();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkScreenSize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    // handle view media function
    const handleViewMedia = async (data) => {
        setViewMediaData(data);
        setShowViewMediaMOdal(!showViewMediaMOdal);

        // function to create thumbnail if video dont have thumbnail
        if (!data?.thumbnail) {

            // required data to create thumbnail
            const requiredData = {
                user_email: data?.email,
                user_auth: process.env.REACT_APP_AUTH,
                task_id: data?.task_id,
                video_link: data?.content_url,
            };

            // send request process to the celery server
            await handleCreateVideoThumbnail(requiredData);
        }
    };

    // Using Intersection Observer API
    useEffect(() => {
        // Initialize IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Video is now in viewport, load the video
                    const video = entry.target;
                    const source = video.querySelector('source');
                    if (source.dataset.src) {
                        source.src = source.dataset.src;
                        video.load();
                    }
                    observer.unobserve(video); // Optionally, stop observing after loading
                }
            });
        }, {
            rootMargin: '50px',
            threshold: 0.01,
        });

        // Observe all current video refs
        videoRefs.current.forEach(video => observer.observe(video));

        // Cleanup
        return () => {
            videoRefs.current.forEach(video => observer.unobserve(video));
        };
    }, [videoData]);

    // handle click outside effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalMediaViewRef.current && !modalMediaViewRef.current.contains(event.target)) {
                setShowViewMediaMOdal(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalMediaViewRef]);

    // Function to extract username or display the full string
    const getUsername = (data) => {

        consoleLog("ProvidedData", data);

        // Check if the username contains '@gmail.com'
        if (data?.includes('@')) {
            // Extract the part of the string before '@gmail.com'
            return `@${data.split('@')[0]}`;
        }
        // If not, return the original username
        return `@${data}`;
    };

    return (
        <>
            {/* {allAccess.includes(subscriptionData?.status?.item_name) && acceptedStatus.includes(subscriptionData?.message) ? ( */}
            {acceptedStatus.includes(subscriptionData?.message) ? (
                <Box sx={{ width: 'auto', height: 'auto', padding: '0.6rem' }}>
                    <ImageList
                        ref={imageListRef}
                        variant="masonry"
                        cols={4}
                        gap={8}
                    >
                        {videoData?.map((item, idx) => {
                            if (item?.content_url) {
                                return (

                                    <ImageListItem
                                        // className="video-card"
                                        key={idx}
                                    >

                                        {/* image media */}
                                        <Image
                                            isZoomed
                                            onClick={() => handleViewMedia(item)}
                                            srcSet={`${item?.thumbnail || (item?.aspect_ratio === "landscape" ? shinefy_default_image_landscape : shinefy_default_image_portrait)}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item?.thumbnail || (item?.aspect_ratio === "landscape" ? shinefy_default_image_landscape : shinefy_default_image_portrait)}?w=248&fit=crop&auto=format`}
                                            className="border-0 rounded-lg bg-gray-800/80 cursor-pointer"
                                            alt={item.title}
                                            loading="lazy"
                                        />

                                        {/* play icon */}
                                        <span
                                            onClick={() => handleViewMedia(item)}
                                            // className="absolute bottom-0 right-0 m-4 text-gray-200 text-4xl cursor-pointer hover:text-gray-300"
                                            className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-purple-500 text-6xl cursor-pointer"
                                        >
                                            <i className="fa-solid fa-circle-play" />
                                        </span>

                                        <ImageListItemBar
                                            title={item?.title || "Shinefy Video"}
                                            subtitle={username || "Shinefy Video"}
                                            sx={{
                                                borderRadius: '0.81rem',
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                zIndex: 50,
                                                // '.MuiImageListItemBar-subtitle': { color: '#D1D5DB', }, // text color
                                            }}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about ${item.title}`}
                                                >
                                                    {/* <InfoIcon /> */}
                                                    <i className="fa-solid fa-thumbs-up text-lg" />
                                                    <span className="text-base ml-1">0</span>
                                                </IconButton>
                                            }
                                            position="top"
                                        />

                                        <ImageListItemBar
                                            sx={{
                                                borderRadius: '0.81rem',
                                                background:
                                                    'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                zIndex: 50,
                                            }}
                                            // title="0"
                                            // position="top"
                                            actionIcon={
                                                <IconButton
                                                    // sx={{ color: 'white' }}
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label="views"
                                                >
                                                    {/* <StarBorderIcon /> */}
                                                    <i className="fa-solid fa-eye text-lg" />
                                                    <span className="text-base ml-1">0</span>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                        />
                                    </ImageListItem>

                                );
                            }
                        })}
                    </ImageList>

                    {/* view media details */}
                    {showViewMediaMOdal && (
                        <ViewMedia
                            modalMediaViewRef={modalMediaViewRef}
                            setShowViewMediaMOdal={setShowViewMediaMOdal}
                            viewMediaData={viewMediaData}
                        />
                    )}
                </Box>
            ) : (
                <div className="flex items-center justify-center h-screen font-bold text-[1.5rem]">
                    <p className="m-[2rem] lg:m-[20rem]">Available only to <span className="text-lime-400">{allAccess?.map(item => `${item}, `)}</span> subscribers.</p>
                </div>
            )}
        </>
    );
};

export default VideoGallery;