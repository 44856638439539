// libraries
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

// custom css
import "./index.css";

// custom components
import App from "./App";
import Hotjar from "./Hotjar";

const root = ReactDOM.createRoot(document.getElementById("root"));
const app = (
    <BrowserRouter>
        <Hotjar />
        <App />
    </BrowserRouter>
);

// Only wrap with React.StrictMode in development
if (process.env.NODE_ENV === 'development') {
    root.render(
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <React.StrictMode>
                {app}
            </React.StrictMode>
        </GoogleOAuthProvider>
    );
} else {
    root.render(
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            {app}
        </GoogleOAuthProvider>
    );
}
