// libraries
import axios from "axios";
import debounce from "lodash.debounce";

// custom components
import consoleLog from "../../../../functions/consoleLog";
import { ToastEffect } from "../../../../functions/ToastEffect";
import formatNumber from "../../../../functions/calculations";

/** fetch all generated videos using get method */
export async function fetchAllToolsData(data) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/fetch_all_tools_data/${process.env.REACT_APP_AUTH}/${data?.email}/${data.type}/${data.start}/${data?.offset}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getGeneratedVideos");
        consoleLog("api > toolsAPI.js > getGeneratedVideos", error);
        // throw error.response;
    }
}

/** fetch notification records */
export async function fetchNotificationData(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/get-notifications`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchNotifications");
        consoleLog("api > toolsAPI.js > fetchNotifications", error);
        // throw error.response;
    }
}

/** get all tools_data base from task_id */
export const fetchMediaData = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/fetch_media_data`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchToolsData");
        consoleLog("api > toolsAPI.js > fetchToolsData", error);
    }
};

/** create video post method function */
export async function createVideo(data) {
    // console.log("data", data);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/image2video`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > createVideo", error);
        throw error.response.data;
        // ToastEffect("error", "Something went wrong! #VideoGenerator");
    }
}

export async function createCustomVideo(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/custom_image2video`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > createCustomVideo", error);
        throw error.response.data;
    }
}

/** process image prompt and count for payment */
export async function calculate_accuracy(data) {
    // console.log("data", data);
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/accuracy_image2video`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > get_image_count", error);
        throw error.response.data;
        // ToastEffect("error", "Something went wrong! #get_image_count");
    }
}

/** video edit enhancer post method function */
export async function videoEnhancer(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/vid_enhancer`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > videoEnhancer", error);
        throw error.response.data;
        // ToastEffect("error", "Something went wrong! #videoEnhancer");
    }
}

/** video transcriber post method function */
export async function videoTranscriber(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/vid_transcriber`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > videoTranscriber", error);
        throw error.response.data;
        // console.log("error", error);
        // ToastEffect("error", "Something went wrong! #videoTranscriber");
    }
}

/** video rebuilder post method function */
export async function videoRebuilder(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/vid_rebuilder`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > videoRebuilder", error);
        throw error.response.data;
        // console.log("error", error);
        // ToastEffect("error", "Something went wrong! #videoTranscriber");
    }
}

/** create custom video function */
export async function createFinalCustomVideo(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/create_custom_video`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > createFinalCustomVideo", error);
        throw error.response.data;
        // console.log("error", error);
        // ToastEffect("error", "Something went wrong! #videoTranscriber");
    }
}

/** call api to request for custom media creation */
export const createCustomMedia = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/create_custom_media`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > createCustomMedia", error);
        throw error.response.data;
    }
};

/** call api to request for syncing the caption to the new audio */
export const syncCaption = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/sync_caption`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > syncCaption", error);
        throw error.response.data;
    }
};

/** call api to request for caption to rebuild */
export const rebuildCaption = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/rebuild_caption`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > rebuildCaption", error);
        throw error.response.data;
    }
};

/** check video status post method function */
export const taskStatus = async (task_id) => {
    const response = await axios
        .get(`${process.env.REACT_APP_API_END_POINT}/tool_vid_status/${task_id}`)
        .catch((error) => {
            ToastEffect("error", "Something went wrong! #taskStatus");
            consoleLog("api > toolsAPI.js > taskStatus", error);
            // throw error;
        });
    // console.log(response);
    return response;
};

/** fetch default voice model from eleven labs get method function */
export async function fetchDefaultVoiceModel() {

    const data = { "xi-api-key": process.env.REACT_APP_ELEVENLAB_API_KEY };

    try {
        const response = await axios.get(`${process.env.REACT_APP_ELEVENLAB_API}/voices`, data,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchDefaultVoiceModel");
        consoleLog("api > toolsAPI.js > fetchDefaultVoiceModel", error);
        // throw error.response;
    }
}

// /** fetch custom voice model from eleven labs get method function */
// export async function fetchCustomVoiceModel(elevenLabsAPIKey) {
//     try {
//         const response = await axios.get(`${process.env.REACT_APP_ELEVENLAB_API}/voices`,
//             {
//                 headers: {
//                     accept: "application/json",
//                     "xi-api-key": elevenLabsAPIKey,
//                 },
//             }
//         );
//         return response;
//     } catch (error) {
//         ToastEffect("error", "Something went wrong! #fetchCustomVoiceModel");
//         consoleLog("api > toolsAPI.js > fetchCustomVoiceModel", error);
//         // throw error.response;
//     }
// }

/** get all generated images using get method */
export const getGeneratedImages = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/get_all_generated_images/${process.env.REACT_APP_AUTH}/${data?.email}/${data.media}/${data.start}/${data?.offset}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getGeneratedImages");
        consoleLog("api > toolsAPI.js > getGeneratedImages", error);
        // throw error.response;
    }
};

/** get all generated videos using get method */
export const fetchGeneratedVideos = async (data) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/get_all_generated_videos/${process.env.REACT_APP_AUTH}/${data?.email}/${data.type}/${data.start}/${data?.offset}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getGeneratedVideos");
        consoleLog("api > toolsAPI.js > getGeneratedVideos", error);
        // throw error.response;
    }
};

/** get result idea checker post method function */
export async function getResultIdeaChecker(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_THE_NEXT_LEG_URL}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_MIDJOURNEY_AUTH_KEY}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getResultIdeaChecker");
        consoleLog("api > toolsAPI.js > getResultIdeaChecker", error);
        // throw error.response;
    }
}

/** delete generated video post method function */
export async function deleteGeneratedVideo(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/remove`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #deleteGeneratedVideo");
        consoleLog("api > toolsAPI.js > deleteGeneratedVideo", error);
        // throw error.response;
    }
}

/** hhandle check link if valid */
export const handleCheckLink = async (sourceLink) => {

    const encodedParams = new URLSearchParams();
    encodedParams.set('url', sourceLink);

    const options = {
        method: 'POST',
        url: process.env.REACT_APP_RAPIDAPI_ENDPOINT,
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_API,
            'X-RapidAPI-Host': process.env.REACT_APP_RAPIDAPI_HOST
        },
        data: encodedParams,
    };

    try {
        const response = await axios.request(options);
        return response.data;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleCheckLink");
        consoleLog("api > toolsAPI.js > handleCheckLink", error);

    }
};

/** handle upload media file */
export const handleUploadMediaFile = async (mediaFile, email, setInputKey, isTranscribing, onProgress) => {
    const sizeInMB = mediaFile?.size / (1024 * 1024); // check file size

    // Extract file extension
    const fileExtension = mediaFile?.name?.split(".").pop().toLowerCase();

    // consoleLog("FileName", mediaFile?.name);
    // consoleLog("FileSize", mediaFile?.size);
    // consoleLog("sizeInMB", sizeInMB);

    // Check if file is not .mp3 or .mp4 type
    if (fileExtension !== "mp3" && fileExtension !== "mp4") {
        setInputKey(Math.random().toString(36)); // This will clear the file input
        return "noMedia";
    }

    // check if file size
    if (sizeInMB > 25) {
        setInputKey(Math.random().toString(36)); // This will clear the file input
        return "sizeExceeds";
    }

    isTranscribing(true);
    const formData = new FormData();
    formData.append("file", mediaFile);
    formData.append("email", email);

    try {
        // Call the API upload_files to upload the file to the server.;
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/upload_media_file`, formData, {

            // header
            headers: {
                "Content-Type": "multipart/form-data",
            },

            // on upload progress
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) {
                    onProgress(percentCompleted); // Call the onProgress callback with the percent completed
                }
            },
        });

        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleUploadMediaFile");
        consoleLog("api > toolsAPI.js > handleUploadMediaFile", error);
    }
};

/** handle upload attached file */
export const handleUploadAttachedFile = async (mediaFile, email, isUploadingAttachedFile, onProgress) => {

    //  is uploading to true
    isUploadingAttachedFile(true);

    // form data
    const formData = new FormData();
    formData.append("file", mediaFile);
    formData.append("email", email);

    try {

        // Call the API upload_files to upload the file to the server.
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/upload_media_file`, formData, {

            // header
            headers: {
                "Content-Type": "multipart/form-data",
            },

            // on upload progress
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) {
                    onProgress(percentCompleted); // Call the onProgress callback with the percent completed
                }
            },
        });

        return response;

    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleUploadMediaFile");
        consoleLog("api > toolsAPI.js > handleUploadMediaFile", error);
    }
};

/** handle submit reporty */
export const handleSubmitReport = async (mediaFile, requiredData, isUploadingAttachedFile, onProgress) => {

    //  is uploading to true
    isUploadingAttachedFile(true);

    // form data
    const formData = new FormData();
    formData.append("attachedFile", mediaFile);
    formData.append("data", JSON.stringify(requiredData));

    try {

        // Call the API upload_files to upload the file to the server.
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/submit-report`, formData, {

            // header
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onProgress(percentCompleted);
            }
        });

        return response;

    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleSubmitReport");
        consoleLog("api > toolsAPI.js > handleSubmitReport", error);
    } finally {
        isUploadingAttachedFile(false); // Set the uploading state back to false once done
    }
};
// export const handleSubmitReport = async (data) => {
//     try {

//         // call the submit repoort API end point.
//         const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/submit_report`, data);
//         return response;
//     } catch (error) {
//         ToastEffect("error", "Something went wrong! #handleSubmitReport");
//         consoleLog("api > toolsAPI.js > handleSubmitReport", error);
//     }
// };

/** Update transcribed data content and title */
export async function updateContentData(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/update_content`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateContentData");
        consoleLog("api > toolsAPI.js > updateContentData", error);
        // throw error.response;
    }
}

/** Update Source Media data content and title */
export async function updateSourceMediaContentData(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/update_media_content`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateSourceMediaContentData");
        consoleLog("api > toolsAPI.js > updateSourceMediaContentData", error);
        // throw error.response;
    }
}

/** update new caption data */
export const updateCaptionData = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/update_caption_data`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #saveNewCaptionData");
        consoleLog("api > toolsAPI.js > saveNewCaptionData", error);
        // throw error.response;
    }
};

/** Update tools data content */
export async function updateTheToolsData(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/update_tools_data`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateTheToolsData");
        consoleLog("api > toolsAPI.js > updateTheToolsData", error);
        // throw error.response;
    }
}

/** Generate image */
export async function generateTheImage(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/image_generator`, data);
        return response;
    } catch (error) {
        consoleLog("api > toolsAPI.js > generateTheImage", error);
        throw error.response.data;
    }
}

/** process image generate*/
export const generateImagePrompts = async (prompt, email, dataID, valueRatio, dataKind, audioFileName, action) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/generate-image-prompts`, {
            email,
            prompt,
            dataID,
            valueRatio,
            dataKind,
            audioFileName,
            action
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getToolsConfig");
        consoleLog("API > account.js > getToolsConfig", error);
    }
};

/** get tools data*/
export const getToolsData = async (email, dataID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/get-tools-data`, {
            email,
            dataID
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getToolsConfig");
        consoleLog("API > account.js > getToolsConfig", error);
    }
};

/** fix prompt */
export const generateImage = async (data, valueRatio, dataKind) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/generate-image`, {
            email: data?.email,
            dataID: data?.id,
            number: data?.number,
            prompt: data?.content,
            title: data?.title,
            ratio: valueRatio,
            dataKind: dataKind
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fixPrompt");
        consoleLog("API > account.js > fixPrompt", error);
    }
};

// /** generate audio */
// export const generateAudio = async (email, dataID, content, voice, dataKind, voiceOwnAPI, elevanLabsAPIKey, voiceId) => {
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/generate-audio`, {
//             email,
//             dataID,
//             content,
//             voice,
//             dataKind,
//             voiceOwnAPI,
//             elevanLabsAPIKey,
//             voiceId
//         });
//         return response;
//     } catch (error) {
//         ToastEffect("error", "Something went wrong! #generateAudio");
//         consoleLog("API > account.js > generateAudio", error);
//     }
// };

/** generate VG_GenerateAudio */
export const generateAudio = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/text_to_speech`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #generate_VT_Audio");
        consoleLog("api > toolsAPI.js > generate_VT_Audio", error);
    }
};

/** fetch audio data */
export const fetchAudioData = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/fetch_audio`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #fetchAudioData");
        consoleLog("api > toolsAPI.js > fetchAudioData", error);
    }
};

/** generate  */
export const generate_VT_Image = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/image_generator`, data);
        return response;
    } catch (error) {
        //
    }
};

/** Upload source media to the back_end */
export const handleUploadMediaSource = async (
    source_media_file,
    source_media_type,
    imageData,
    start_duration,
    end_duration,
    video_duration,
    is_muted,
    image_id
) => {

    const formData = new FormData();
    formData.append("auth_access", process.env.REACT_APP_AUTH);
    formData.append("source_media_file", source_media_file);
    formData.append("source_media_type", source_media_type);
    formData.append("task_id", imageData?.task_id);
    formData.append("email", imageData?.email);
    formData.append("number", imageData?.number);
    formData.append("start_duration", formatNumber(start_duration));
    formData.append("end_duration", formatNumber(end_duration));
    formData.append("video_duration", formatNumber(video_duration));
    formData.append("is_muted", is_muted);
    formData.append("image_id", image_id);

    try {

        consoleLog("formData", formData);

        // Call the API upload_files to upload the file to the server.;
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/upload_media_source`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleUploadMediaFile");
        consoleLog("api > toolsAPI.js > handleUploadMediaFile", error);
    }
};

/** re process the faild generations */
export const handleReProcessVG = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/re-queue-process`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleReProcessVG");
        consoleLog("api > toolsAPI.js > handleReProcessVG", error);
    }
};

/** create video thumbnail */
export const handleCreateVideoThumbnail = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/create_thumbnail`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleCreateVideoThumbnail");
        consoleLog("api > toolsAPI.js > handleCreateVideoThumbnail", error);
    }
};

/** optimize image */
export const handleOptimizeImage = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/optimize_image`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #handleOptimizeImage");
        consoleLog("api > toolsAPI.js > handleOptimizeImage", error);
    }
};

/** update notification status */
export const updateNotifStatus = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/notif-read`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #updateNotifStatus");
        consoleLog("api > toolsAPI.js > updateNotifStatus", error);
        // throw error.response;
    }
};

/** send request to the app server to fetch interaction data */
export const fetchInteracts = (data) => {
    return new Promise((resolve, reject) => {
        debouncedFetchInteracts(data, resolve, reject);
    });
};

// debounced sub function
const debouncedFetchInteracts = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/get-notif-interacts`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedFetchInteracts", error);
        reject(error);
    }
}, 500);

/** mark notification from the database as deleted */
export const removeNotifData = async (dataID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/account/notif-remove`, dataID);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #removeNotifData");
        consoleLog("api > toolsAPI.js > removeNotifData", error);
        // throw error.response;
    }
};

/** send request to the app server to craft video content */
export const craftVideoContent = (data) => {
    return new Promise((resolve, reject) => {
        debouncedCraftVideoContent(data, resolve, reject);
    });
};

// debounced sub function
const debouncedCraftVideoContent = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/craft_video`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedCraftVideoContent", error);
        reject(error);
    }
}, 500);

/** send request to the app server to re-craft video content */
export const reCraftVideoContent = (data) => {
    return new Promise((resolve, reject) => {
        debouncedReCraftVideoContent(data, resolve, reject);
    });
};

// debounced sub function
const debouncedReCraftVideoContent = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/re_craft_video`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedReCraftVideoContent", error);
        reject(error);
    }
}, 500);

/** upload media file to the server */
export const uploadMediaFile = (data, onProgress) => {
    return new Promise((resolve, reject) => {
        debouncedUploadMediaFile(data, onProgress, resolve, reject);
    });
};

// sub function that debounced the process
const debouncedUploadMediaFile = debounce(async (data, onProgress, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/upload_artisan_media`, data, {

            headers: { // header
                'Content-Type': 'multipart/form-data',
            },

            // on upload progress
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) {
                    onProgress(percentCompleted); // Call the onProgress callback with the percent completed
                }
            },

        });
        resolve(response);
    } catch (error) {
        consoleLog("debouncedUploadMediaFile", error);
        reject(error);
    }
}, 500);

/** upload own media file to the server */
export const uploadOwnMediaFile = (data, onProgress) => {
    return new Promise((resolve, reject) => {
        debouncedUploadOwnMediaFile(data, onProgress, resolve, reject);
    });
};

// sub function that debounced the process
const debouncedUploadOwnMediaFile = debounce(async (data, onProgress, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/upload_own_media`, data, {

            headers: { // header
                'Content-Type': 'multipart/form-data',
            },

            // on upload progress
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (onProgress) {
                    onProgress(percentCompleted); // Call the onProgress callback with the percent completed
                }
            },

        });
        resolve(response);
    } catch (error) {
        consoleLog("debouncedUploadOwnMediaFile", error);
        reject(error);
    }
}, 500);