// libraries
import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Textarea, Tooltip } from "@nextui-org/react";
import moment from "moment";

// custom components
import consoleLog from "../../../../functions/consoleLog";
import { cachedToolsConfiguration } from "../../../../functions/general";
import { sendDMToTheUser } from "../../../../API/server";

const ChatBox = (props) => {

    // props
    const {
        filterChatConvos,
        setRefetchBotChats,
        adminEmail,
    } = props;

    // useRef
    const endOfMessagesRef = useRef(null);

    // variables
    const maxRows = 8;
    const minRows = 1;
    let chatBotavatar = "user.jpg"; // chat bot avatar

    if (filterChatConvos?.avatar) {
        // get avatar source
        chatBotavatar = `${process.env.REACT_APP_DISCORD_ENDPOINT}/avatars/${filterChatConvos?.userID}/${filterChatConvos?.avatar}.png`;
    }

    // react useState
    const [rows, setRows] = useState(minRows);
    const [message, setMessage] = useState("");
    const [isSubmittingDM, setIsSubmittingDM] = useState(false);

    // console log activity
    // consoleLog("filterChatConvos", filterChatConvos);

    // handle on change
    const handleChange = (event) => {

        // extracted value
        const { value } = event.target;

        // set value
        setMessage(value);

        // cached config to localstorage
        // cachedToolsConfiguration("dm", value);

        const lineBreaks = (value.match(/\n/g) || []).length + minRows;
        setRows(Math.min(lineBreaks, maxRows));
    };

    // handle on key down
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default newline behavior
            // call the function
            sendDMFunction();
        }
    };

    // send message sub function
    const sendDMFunction = () => {
        // Check if the message contains non-whitespace characters
        if (message.trim().length > 0) {
            sendTheDM();
        }
    };

    // const handle sending the DM chat
    const sendTheDM = async () => {

        // check if "filterChatConvos" exist
        if (!filterChatConvos) return;

        // required data
        const requiredData = {
            message: message,
            sentTo: "one",
            userId: filterChatConvos?.userID,
            sender: adminEmail,
        };

        // enabling loading mark
        setIsSubmittingDM(true);

        // await handleSendingDM;
        await sendDMToTheUser(requiredData)
            .then((response) => {
                // refetch bot chat data
                setRefetchBotChats((val) => val + 1);
            });

        // empty the message
        setMessage("");

        // disabling loading mark
        setIsSubmittingDM(false);

        // console log activity
        consoleLog("filterChatConvos", filterChatConvos);
        consoleLog("requiredData", requiredData);
    };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            // endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
            endOfMessagesRef.current.scrollIntoView();
        }
    }, [filterChatConvos]);

    return (
        <div className="flex flex-col flex-auto h-full p-6">
            <div className="flex flex-col flex-auto flex-shrink-0 rounded-lg bg-gray-100 dark:bg-gray-800 h-full p-4">
                <div className="flex flex-col h-full overflow-x-auto mb-4">
                    <div className="flex flex-col h-full">

                        {/* chat box content */}
                        <div className="grid grid-cols-12 gap-y-2">

                            {/* mapping the bot chat convos */}
                            {filterChatConvos?.dmsData?.map((data, idx) => {

                                // console log activity
                                // consoleLog("data", data);

                                if (data?.authorData?.bot) {
                                    return ( // right side chat
                                        <div ref={endOfMessagesRef} key={idx} className="col-start-6 col-end-13 p-3 rounded-lg">
                                            <div className="flex items-center justify-start flex-row-reverse">
                                                <span className="flex items-center justify-center flex-shrink-0">
                                                    <Avatar showFallback src="logo192.png" />
                                                </span>
                                                <div>
                                                    <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                                                        <div>{data?.messageContent}</div>
                                                    </div>
                                                    <small className="text-gray-500 dark:text-gray-400 float-right text-xs mr-[1.5rem] mt-0.5">
                                                        {moment(data.createdAt).fromNow()}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return ( // left side chat
                                        <div ref={endOfMessagesRef} key={idx} className="col-start-1 col-end-8 p-3 rounded-lg">
                                            <div className="flex flex-row items-center">
                                                <span className="flex items-center justify-center flex-shrink-0">
                                                    <Avatar showFallback src={chatBotavatar} />
                                                </span>
                                                <div>
                                                    <div className="relative ml-3 text-sm bg-white dark:bg-gray-700 dark:text-gray-100 py-2 px-4 shadow rounded-xl" >
                                                        <p className="whitespace-pre-wrap">{data?.messageContent}</p>
                                                    </div>
                                                    <small className="text-gray-500 dark:text-gray-400 float-left text-xs ml-[1.5rem] mt-0.5">
                                                        {moment(data.createdAt).fromNow()}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}

                        </div>

                    </div>
                </div>

                {filterChatConvos?.dmsData?.length ? (
                    <>
                        {/* <div className="flex flex-row items-center h-16 rounded-xl bg-white dark:bg-gray-700 w-full px-4"> */}
                        <div className="flex flex-row items-center w-full px-4">

                            <div className="relative mx-2 w-full dark:text-gray-100">
                                <Textarea
                                    disabled={isSubmittingDM ? true : false}
                                    size="lg"
                                    radius="sm"
                                    label="Direct Message"
                                    variant="bordered"
                                    placeholder="Enter your message"
                                    disableAnimation
                                    disableAutosize
                                    classNames={{
                                        base: "h-full",
                                    }}
                                    value={message}
                                    onValueChange={setMessage}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    rows={rows}
                                    style={{ minHeight: '40px' }}
                                />
                                <div className="absolute bottom-2 right-1">
                                    <Tooltip showArrow={true} color="primary" content="Send DM">
                                        <Button
                                            isIconOnly
                                            size="sm"
                                            variant="light"
                                            startContent={
                                                !isSubmittingDM && (
                                                    <span className="text-2xl text-gray-500 dark:text-gray-500 hover:text-gray-400">
                                                        <i className="fa-solid fa-paper-plane" />
                                                    </span>
                                                )
                                            }
                                            onClick={sendDMFunction}
                                            isLoading={isSubmittingDM ? true : false}
                                        />
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                    </>) : (<></>)}
            </div>
        </div>
    );
};

export default ChatBox;