// libraries
import React, { useContext } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea, Tooltip } from "@nextui-org/react";

// custom components
import UserContext from "../../../../components/UserContext";
import { cachedToolsConfiguration } from "../../../../functions/general";
import { estimateAudioLength } from "./general";

const PromptEditor = (props) => {

    // props
    const {
        isOpen,
        onClose,
        // isMobile,
        promptKind,
        contentValueIdea,
        contentValueScript,
        handleFunctionIdea,
        handleFunctionScript,
        // modalPromptEditor,
        // setShowPromptEditor,
    } = props;

    // context method
    const {

        // others
        userData,

        // debounce saveConfig function
        debouncedSaveConfig,

    } = useContext(UserContext);

    // variables
    const email = userData?.UserInfo?.email;

    // manual save content when save button is clicked
    const handleManualSaveContent = () => {

        // declare variables
        let updatedInputValue = "";
        const kind = "userScriptContent";
        const path = "/general-purpose";

        // conditional check for "promptKind"
        if (promptKind === "idea") {
            updatedInputValue = contentValueIdea || "";
        } else {
            updatedInputValue = contentValueScript || "";
        }

        // cached config to localstorage
        cachedToolsConfiguration(kind, updatedInputValue);

        // Call the debounced function
        debouncedSaveConfig(promptKind, email, path, promptKind, updatedInputValue);

        // finally close the modal editor
        onClose();

    };

    return (
        <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
            className="dark:bg-gray-900"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            Prompt Editor {promptKind === "idea" ? "(Idea)" : "(Script)"}
                        </ModalHeader>
                        <ModalBody>

                            <Textarea
                                onChange={promptKind === "idea" ? handleFunctionIdea : handleFunctionScript}
                                value={promptKind === "idea" ? contentValueIdea || "" : contentValueScript || ""}
                                minRows={20}
                                maxRows={20}
                                size="lg"
                                variant="faded"
                                label={promptKind === "idea" ? "Prompt Idea" : "Own Script"}
                                // description="Enter a brief image prompt for generating an image."
                                className="w-full text-lg"
                                placeholder="Write your image prompt here..."
                            />

                        </ModalBody>

                        <ModalFooter className="flex flex-col lg:flex-row items-center justify-end -mt-4">

                            {/* Start */}
                            <div className="w-full mb-0 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700/40 dark:border-gray-600">

                                <div className="flex font-semibold font-sans space-x-1 items-center justify-between px-3 py-2 border-t dark:border-gray-600 bg-gray-100 rounded-lg dark:bg-gray-800">



                                    <div className="flex items-center justify-end space-x-1 text-gray-800">

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Characters"
                                        >
                                            <div
                                                type="button"
                                                className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                            >
                                                <i className="fa-solid fa-font mr-1" />
                                                <code>{promptKind === "idea" ? (contentValueIdea?.length || 0)?.toLocaleString() : (contentValueScript?.length || 0)?.toLocaleString()}</code>
                                            </div>
                                        </Tooltip>

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Words"
                                        >
                                            <div
                                                type="button"
                                                className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800"
                                            >
                                                <i className="fa-solid fa-arrow-up-z-a mr-1" />
                                                <code>{(estimateAudioLength(promptKind === "idea" ? contentValueIdea : contentValueScript)?.count || 0)?.toLocaleString()}</code>
                                            </div>
                                        </Tooltip>

                                        {promptKind !== "idea" && (
                                            <>
                                                <Tooltip
                                                    color="primary"
                                                    placement="top"
                                                    showArrow={true}
                                                    content="Estimated video length"
                                                >
                                                    <div className="inline-flex justify-center items-center p-2 text-gray-500 rounded-lg hover:text-gray-900 bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:bg-gray-800">
                                                        <i className="fa-regular fa-clock mr-1" />
                                                        <code>{estimateAudioLength(promptKind === "idea" ? contentValueIdea : contentValueScript)?.length}</code>
                                                    </div>
                                                </Tooltip>

                                                <div className="mt-1">

                                                    <Tooltip
                                                        color="primary"
                                                        placement="top"
                                                        showArrow={true}
                                                        content={
                                                            <div className="px-1 py-2">
                                                                <div className="text-small font-bold">Video Generator Tip</div>
                                                                <ul className="list-disc pl-5 space-y-1 break-words w-[30rem]">
                                                                    <li><b>Note:</b> The estimated video length may not be accurate. Sometimes, it might be slightly greater or less than the actual length.</li>
                                                                </ul>
                                                            </div>
                                                        } >
                                                        <span className="text-warning dark:text-yellow-300 text-2xl dark:hover:text-yellow-400">
                                                            <i className="fa-solid fa-circle-question" />
                                                        </span>
                                                    </Tooltip>

                                                </div>
                                            </>
                                        )}

                                    </div>


                                    <div className="flex space-x-1">

                                        {/* <div
                                            data-tooltip-id="customize-audio-prompt-updating"
                                            data-tooltip-content="Saving content data"
                                            className="inline-flex items-center py-1 w-auto font-medium dark:text-gray-200 text-gray-700"
                                        >
                                            <Tooltip id="customize-audio-prompt-updating" />
                                            <i className="fa-solid fa-circle-notch fa-spin text-xl" />
                                        </div> */}

                                        <Tooltip
                                            color="primary"
                                            placement="top"
                                            showArrow={true}
                                            content="Save Image Prompt"
                                        >
                                            <button
                                                // disabled={isManualSaving ? true : false}
                                                onClick={handleManualSaveContent}
                                                type="submit"
                                                className="inline-flex items-center py-1 w-auto px-3 font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                            >
                                                <i className="fa-solid fa-floppy-disk text-lg mr-1" />
                                                Save prompt
                                            </button>
                                        </Tooltip>

                                    </div>

                                </div>
                            </div>

                            {/* End */}
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal >
    );
};

export default PromptEditor;