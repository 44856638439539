import React, { useContext, useEffect, useState } from "react";
import { saveAccountInfo } from "../../API/account";
import UserContext from "../UserContext";

const Information = () => {

    const { userData } = useContext(UserContext);

    // use state
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [newdata, setNewData] = useState({});
    const [debouncedValue, setDebouncedValue] = useState(newdata.value);
    const [isProcessing, setIsProcessing] = useState("");

    // handle first name function
    const handleFirstName = (event) => {
        setIsProcessing("first_name");
        const newValue = event.target.value;
        setFirstName(newValue);
        if (newValue && newValue.length <= 20) {
            debounceFunction({ value: newValue, kind: "first_name" });
        }
    };

    // handle last name function
    const handleLastName = (event) => {
        setIsProcessing("last_name");
        const newValue = event.target.value;
        setLastName(newValue);
        if (newValue && newValue.length <= 20) {
            debounceFunction({ value: newValue, kind: "last_name" });
        }
    };

    // debounce saveConfig function
    const debounceFunction = (data) => {
        setNewData(data);
    };

    // Update debouncedValue after 1000ms the user has stopped typing
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(newdata.value);
            setIsProcessing("");
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [newdata]);

    // Call handleMidjourneyAPIKey when debouncedValue changes
    useEffect(() => {
        handleDebounceFunction(debouncedValue);
    }, [debouncedValue]);

    // save debounced value
    const handleDebounceFunction = (value) => {

        if (!value) {
            return;
        }

        const email = userData?.UserInfo?.email;
        saveAccountInfo(email, newdata.kind, value);
    };

    return (
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h3 className="mb-4 text-xl font-semibold dark:text-white">
                General information
                <small className="text-gray-500 ml-1 text-sm">(Please relogin for the changes to take effect.)</small>
            </h3>
            <form action="#">
                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                        <input
                            disabled={isProcessing === "last_name" ? true : false}
                            onChange={handleFirstName}
                            value={firstName}
                            type="text"
                            name="first-name"
                            id="first-name"
                            maxLength={20}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-300 disabled:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={userData?.UserInfo?.first_name}
                        />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                        <input
                            disabled={isProcessing === "first_name" ? true : false}
                            onChange={handleLastName}
                            value={lastName}
                            type="text"
                            name="last-name"
                            id="last-name"
                            maxLength={20}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-300 disabled:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={userData?.UserInfo?.last_name}
                        />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country</label>
                        <input type="text" name="country" id="country" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="United States" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City</label>
                        <input type="text" name="city" id="city" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. San Francisco" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address</label>
                        <input type="text" name="address" id="address" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. California" required="" />
                    </div> */}

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                        <input
                            disabled
                            type="text"
                            name="username"
                            id="username"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-300 disabled:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={userData?.UserInfo?.username}
                        />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contact</label>
                        <input type="number" name="phone-number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. +(12)3456 789" required="" />
                    </div> */}

                    <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <input
                            disabled
                            type="email"
                            name="email"
                            id="email"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-300 disabled:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={userData?.UserInfo?.email}
                        />
                    </div>

                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="birthday" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Birthday</label>
                        <input type="datetime-local" name="birthday" id="birthday" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="15/08/1990" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="organization" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Organization</label>
                        <input type="text" name="organization" id="organization" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Company Name" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="role" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Role</label>
                        <input type="text" name="role" id="role" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="React Developer" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="department" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                        <input type="text" name="department" id="department" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Development" required="" />
                    </div> */}
                    {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="zip-code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Zip/postal code</label>
                        <input type="number" name="zip-code" id="zip-code" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123456" required="" />
                    </div> */}

                    <div className="col-span-6 sm:col-full">
                        {/* <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit">
                            Save Changes
                        </button> */}
                    </div>

                </div>
            </form>
        </div>
    );
};

export default Information;