// libraries
import Swal from "sweetalert2";

export const cantProcessLink = async (link, kind) => {

    // let color = "";

    // if (localStorage.theme === "dark") {
    //   color = "#111827";
    // } else {
    //   color = "";
    // }


    const result = await Swal.fire({
        background: "#111827",
        title: `<strong class="text-gray-700 dark:text-gray-200">Download <a class="underline text-blue-500" href="${link}" target="_blank">link</a></strong>`,
        icon: 'info',
        html:
            `<p class="text-gray-700 dark:text-gray-300">
                ${kind === "note" ? "<b>Note</b> that some links may not be processable." : "<b>Unfortunately</b>, we are unable to automate this link"}
            </p>` +
            `<p class="text-gray-700 dark:text-gray-500">
                ${kind === "note" ? "If issues arise, manually download and upload the file." : "Download and then manually upload the file."}
            </p>`,
        // showCloseButton: true,
        // showCancelButton: true,
        showConfirmButton: kind === "note" ? true : false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Got it!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        // cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        // cancelButtonAriaLabel: 'Thumbs down'
    });

    if (result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.close) {
        return "Modal was closed";
    }

};