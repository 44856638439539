// libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IconButton, ImageListItemBar } from "@mui/material";
import { Image } from "@nextui-org/react";
import { throttle } from "lodash";

// custom components
import { getGeneratedImages, handleOptimizeImage } from "../Tools/API/toolsAPI";
import UserContext from "../../../components/UserContext";
import consoleLog from "../../../functions/consoleLog";
import shinefy_default_video_thumbnail from "../../../constant/images/shinefy_default_video_thumbnail.png";
import default_thumbnail from "../../../constant/images/default_thumbnail.png";
import ViewMedia from "./ViewMedia";
import shinefy_default_image_portrait from "../../../constant/images/default_image_display_portrait.png";
import shinefy_default_image_landscape from "../../../constant/images/default_image_display_landscape.png";
import { acceptedStatus, allAccess } from "../../../Configs/planData";

const ImageGallery = () => {

    const {
        // other context data
        userData,
        toolsDataStatus,
        subscriptionData,
        paymentPrice,
        userConfig,
        refetchData,

        // For gallery Images
        imageData,
        setImageData,
        getImageData
    } = useContext(UserContext);

    const email = userData?.UserInfo?.email;

    // userefs, navigates and use location
    const imageListRef = useRef(null);
    const modalMediaViewRef = useRef(null);

    // set the image count to be fetched
    const itemsPerPage = 30; // Display the first fetched images including their status: processing, deleted, fail.

    // use state
    // const [imageData, setImageData] = useState([]);
    const [onErrorImageLoad, setOnErrorImageLoad] = useState({});
    const [offset, setOffset] = useState(0);
    const [showViewMediaMOdal, setShowViewMediaMOdal] = useState(false);
    const [viewMediaData, setViewMediaData] = useState([]);

    // console logs
    // consoleLog("imageData", imageData);

    // refetch image data 
    useEffect(() => {
        const refetchImageData = () => {
            // Create a new array with the updated thumbnails
            const updatedData = imageData.map(item => {

                // sort data via task_id
                const imageStatus = toolsDataStatus?.find((status) =>
                    status?.tools_status?.task_id === item._id
                );

                // update thumbnail url
                if (item._id === imageStatus?.tools_status?.task_id) {
                    return { ...item, optimized_image: imageStatus?.tools_status?.message };
                }

                // return processed data
                return item;
            });

            // Update the state with the new array
            setImageData(updatedData);
        };
        refetchImageData();
    }, [refetchData]);

    // infinite scroll function
    useEffect(() => {
        const handleScroll = throttle(async () => {
            if (imageListRef.current) {
                // Check if the scroll position is at almost the bottom
                // For window scroll, compare window scroll positions to document body's height
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const scrollHeight = document.documentElement.scrollHeight;
                const clientHeight = window.innerHeight;
                const threshold = 200; // Pixels from the bottom when you start fetching new data

                // Check if the scroll position is close to the bottom
                if (scrollTop + clientHeight >= scrollHeight - threshold) {

                    const newOffset = offset + itemsPerPage; // Use the current offset state
                    setOffset(newOffset); // Update the offset state

                    const requiredData = {
                        email: email,
                        start: itemsPerPage,
                        media: "image",
                        offset: newOffset, // Use the updated offset
                        kind: "scroll"
                    };

                    getImageData(requiredData);
                    // consoleLog('Reached the bottom of the ImageList', `${scrollTop}-${clientHeight}-${scrollHeight}`);
                }
            }
        }, 100);

        // Listen to scroll event on window
        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => window.removeEventListener('scroll', handleScroll);
    }, [offset]);

    // useeffect for fetching image data on load / reload
    useEffect(() => {
        getImageData();
    }, []);

    // handle view media function
    const handleViewMedia = async (data) => {
        setViewMediaData(data);
        setShowViewMediaMOdal(!showViewMediaMOdal);

        if (!data?.optimized_image) {

            // required data to optimize image
            const requiredData = {
                user_email: data?.email,
                user_auth: process.env.REACT_APP_AUTH,
                task_id: data?._id,
                image_link: data?.data,
            };

            // send request process to the celery server
            await handleOptimizeImage(requiredData);
        }
    };

    // handle click outside effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalMediaViewRef.current && !modalMediaViewRef.current.contains(event.target)) {
                setShowViewMediaMOdal(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalMediaViewRef]);

    return (
        <>
            {/* {allAccess.includes(subscriptionData?.status?.item_name) && acceptedStatus.includes(subscriptionData?.message) ? ( */}
            {acceptedStatus.includes(subscriptionData?.message) ? (
                <Box sx={{ width: 'auto', height: 'auto', padding: '0.6rem' }}>
                    <ImageList
                        ref={imageListRef}
                        variant="masonry"
                        cols={4}
                        gap={8}
                    >
                        {imageData.map((item, idx) => {
                            if (item?.data) {
                                return (
                                    <ImageListItem key={idx}>

                                        <Image
                                            isZoomed
                                            onClick={() => handleViewMedia(item)}
                                            // onError={(e) => handleOnErrorImageLoad(e, idx)}
                                            // onLoad={(e) => handleOnErrorImageLoad(e, idx)}
                                            className="border-0 rounded-lg bg-gray-800/80 cursor-pointer object-contain"
                                            srcSet={`${item?.optimized_image || (item?.ratio === "landscape" ? shinefy_default_image_landscape : shinefy_default_image_portrait)}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item?.optimized_image || (item?.ratio === "landscape" ? shinefy_default_image_landscape : shinefy_default_image_portrait)}?w=248&fit=crop&auto=format`}
                                            alt={item?.number}
                                            // fallbackSrc="https://via.placeholder.com/300x200" // default image incase image is still loading
                                            loading="lazy"
                                        />

                                        {/* image icon */}
                                        {/* {onErrorImageLoad[idx] && (
                                        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-5xl cursor-pointer">
                                            <i className="fa-regular fa-image" />
                                        </span>
                                    )} */}

                                        <ImageListItemBar
                                            sx={{
                                                borderRadius: '0.81rem',
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                zIndex: 50,
                                            }}
                                            title={item.title}
                                            position="top"
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'white' }}
                                                    aria-label={`star ${item.title}`}
                                                >
                                                    <i className="fa-solid fa-thumbs-up text-lg" />
                                                    <span className="text-base ml-1">0</span>
                                                </IconButton>
                                            }
                                            actionPosition="left"
                                        />

                                    </ImageListItem>
                                );
                            }
                        })}
                    </ImageList>
                </Box>
            ) : (
                <div className="flex items-center justify-center h-screen font-bold text-[1.5rem]">
                    <p className="m-[2rem] lg:m-[20rem]">Available only to <span className="text-lime-400">{allAccess?.map(item => `${item}, `)}</span> subscribers.</p>
                </div>
            )}

            {/* view media details */}
            {showViewMediaMOdal && (
                <ViewMedia
                    modalMediaViewRef={modalMediaViewRef}
                    setShowViewMediaMOdal={setShowViewMediaMOdal}
                    viewMediaData={viewMediaData}
                />
            )}
        </>
    );
};

export default ImageGallery;