// libraries
import moment from "moment";
import validator from 'validator';

// custom imports
import consoleLog from "../../../../functions/consoleLog";
import { ToastEffect } from "../../../../functions/ToastEffect";

// check if what ratio is a video
export function getAspectRatioType(ratio) {
    switch (ratio) {
        case "portrait": return "9:16";
        case "landscape": return "16:9";
    }
}

// check if all images from edit "Custom Video" are available.
export const checkAllImagesAvailability = (arrayData) => {
    if (arrayData.length === 0) return false; // Assume false for empty arrays, adjust based on your use case
    const filteredArray = arrayData.filter(item => item.data && item.data.trim() !== "");
    return filteredArray.length === arrayData.length;
};

// check if audio from edit "Custom Video" is available
export const checkAudioAvailability = (audioData) => {
    if (audioData?.data !== "") {
        return true;
    } else {
        return false;
    }

};

// bulk generate images
export const bulkGenerateImages = async (toolsDataImage, handleGenerateImage, setIsBulkGeneratingImages) => {

    // Find the first item in toolsDataImage that has no data
    const nextDataToProcess = toolsDataImage.find(item => !item.data);

    // If there's an item to process
    if (nextDataToProcess) {
        consoleLog("nextDataToProcess", nextDataToProcess);
        setIsBulkGeneratingImages(true); // Set is bulk generation started
        await handleGenerateImage(nextDataToProcess);  // Process the found item
    }
};

// auto open modal prompt editor if character count is >= 300
export const autoOpenModalPromptEditor = (characterCount, onOpenSE) => {
    if (characterCount?.length >= 300) {
        onOpenSE();
    }
};

// trigger a warning
export const showCharacterCountWarning = (characterCount, message) => {
    if (characterCount?.length > 4000) {
        ToastEffect("warning", message);
    }
};

// etimate text to video lenght base from WPM (word per menute)
export const estimateAudioLength = (text, wordsPerMinute = 145) => {
    const words = text?.trim().split(/\s+/);
    const count = words?.length || 0;
    const estimatedMinutes = count / wordsPerMinute;
    const hours = Math.floor(estimatedMinutes / 60);
    const minutes = Math.floor(estimatedMinutes % 60);
    const seconds = Math.round((estimatedMinutes * 60) % 60);

    let timeParts = [];
    if (hours > 0) timeParts.push(`${hours}hr`);
    if (minutes > 0) timeParts.push(`${minutes}min`);
    if (seconds > 0 || timeParts.length === 0) timeParts.push(`${seconds}sec`);

    return { length: timeParts.join(', '), count };
};

// // get ramaining date and time base from the start and end time
// export const calculateRemainingTime = (startDateString, endDateString) => {

//     const startDate = moment(startDateString);
//     const endDate = moment(endDateString);
//     const duration = moment.duration(endDate.diff(startDate));

//     if (duration.asMilliseconds() < 0) {
//         return 'The end date is before the start date.';
//     }

//     let remainingTime = '';
//     if (duration.years() > 0) {
//         remainingTime = `${duration.years()} year${duration.years() > 1 ? 's' : ''} left`;
//     } else if (duration.months() > 0) {
//         remainingTime = `${duration.months()} month${duration.months() > 1 ? 's' : ''} left`;
//     } else if (duration.days() > 0) {
//         remainingTime = `${duration.days()} day${duration.days() > 1 ? 's' : ''} left`;
//     } else if (duration.hours() > 0) {
//         remainingTime = `${duration.hours()} hr${duration.hours() > 1 ? 's' : ''} left`;
//     } else if (duration.minutes() > 0) {
//         remainingTime = `${duration.minutes()} min${duration.minutes() > 1 ? 's' : ''} left`;
//     } else if (duration.seconds() > 0) {
//         remainingTime = `${duration.seconds()} sec${duration.seconds() > 1 ? 's' : ''} left`;
//     }

//     return remainingTime;
// };

// get ramaining date and time base from the start and end time
export const calculateRemainingTime = (endDateString, kind) => {
    const now = moment(); // Current date and time
    const endDate = moment(endDateString);
    const duration = moment.duration(endDate.diff(now)); // Calculate the difference between endDate and now

    if (duration.asMilliseconds() < 0) {
        return "The credits have expired";
    }

    let remainingTime = "";
    let textExtension = "";

    if (kind === "monthly_credits") {
        textExtension = "until the reset";
    } else if (kind === "paid_credits") {
        textExtension = "left until it expires";
    }

    if (duration.years() > 0) {
        remainingTime = `${duration.years()} year${duration.years() > 1 ? 's' : ''} ${textExtension}`;
    } else if (duration.months() > 0) {
        remainingTime = `${duration.months()} month${duration.months() > 1 ? 's' : ''} ${textExtension}`;
    } else if (duration.days() > 0) {
        remainingTime = `${duration.days()} day${duration.days() > 1 ? 's' : ''} ${textExtension}`;
    } else if (duration.hours() > 0) {
        remainingTime = `${duration.hours()} hr${duration.hours() > 1 ? 's' : ''} ${textExtension}`;
    } else if (duration.minutes() > 0) {
        remainingTime = `${duration.minutes()} min${duration.minutes() > 1 ? 's' : ''} ${textExtension}`;
    } else if (duration.seconds() > 0) {
        remainingTime = `${duration.seconds()} sec${duration.seconds() > 1 ? 's' : ''} ${textExtension}`;
    } else {
        return "No extra credits";
    }

    return remainingTime;
};

// display paid credits
export const displayPaidCreditsValue = (endDateString, pCreditValue) => {
    const now = moment(); // Current date and time
    const endDate = moment(endDateString);
    const duration = moment.duration(endDate.diff(now)); // Calculate the difference between endDate and now

    if (duration.asMilliseconds() < 0) {
        return 0;
    } else {
        return pCreditValue;
    }
};

// check if paid credits have expired.
export const checkPCreditsHaveExpired = (userData) => {

    // current date now
    const now = new Date();

    if (userData && userData.p_credits_expiration && new Date(userData.p_credits_expiration) > now) {
        return true;
    } else {
        return false;
    }
};

// extract the last task id from sub task ids
export const extract_last_task_id = (task_ids) => {
    if (task_ids) {
        return task_ids.split(',').pop();
    } else {
        return "";
    }
};

// update tools data image
export const updateToolsDataImage = (toolsDataArray, new_task_id, image_id) => {
    const updatedDataArray = toolsDataArray.map(item => {
        if (item.id === image_id) { // Assuming you're looking for this ID
            return { ...item, sub_task_ids: new_task_id };
        }
        return item;
    });

    return updatedDataArray;
};

/** percentage calculator adjuster */
export const scaleValueTo100 = (value, oldMax) => {
    const newMin = 1;
    const newMax = 100;
    const oldMin = 1;
    return ((value - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;
};

/** check if data contains URL or a link */
export const containsUrl = (string) => {
    if (!string) return false; // Return false if the string is empty or null
    return validator.isURL(string);
};

/** unit amount conversion and do a clean format with commas */
export const unitAmountConversions = (value) => {
    const dollars = value / 100;
    return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 0 }).format(dollars);
};

/** check trials count */
export const trialCount = (days) => {
    if (days > 1) {
        return `${days} days trial`;
    } else {
        return `${days} day trial`;
    }
};