// libraries
import React, { useContext, useRef, useState } from "react";

// custom components
import consoleLog from "../../functions/consoleLog";
import UserContext from "../../components/UserContext";
import { acceptedStatus, SRPageConfig } from "../../Configs/planData";

const SRPage = () => {

    // context method
    const {

        // Others
        subscriptionData,

    } = useContext(UserContext);

    // react use ref
    // const iframeRef = useRef(null);

    // get SR access token from localstorage
    const SRAccessToken = localStorage.getItem('SRAccesstoken');
    // const SRAccessToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiaXNzIjoiU2hpbmVSYW5rZXIiLCJzdWIiOiJ0ZXN0M3N3ZW5nZ0BnbWFpbC5jb20iLCJpYXQiOjE3MjY1NTE3OTMuMCwiZXhwIjoxNzI2NTU1MzkzLjAsIm5hbWUiOiJUZXN0IERldiIsImJhbGFuY2UiOjk4Nzc2OTksImlzX3RyaWFsIjpmYWxzZX0.BleA0C4O52OBsHGwNlx42S9H7gu9UgYH4CRrzh_vIdozm0-T7VvnZXLo5kfMW0HydvYso85FksplLSLwUkd6QB_evloJnplnDsYE6fcJ-Cvo5qAoNHCl6_9ACTyoBPQmMMwO_6J5VGCvVbpKnGJnCmzDG_GSLvXDWYGDBRAfldwvj1NfE9-lLN-hcaa0yvjuO9RiOVVYvBys8QZa0QuMmd36Yxpvg1LTW-tDpTwbVRRQCN_zZUuHBF0WyItGJDz_mFsP4-hO2pj8YM2hrI2VfKlEsswrjnTZG4kYUXJnGyt4XvKg3E2hW8oZidbUI6R1cXInx4hA8Rdd-yRTfkSqGgdg2Ew2AxKgPQg5YYU_LsOVWulRq4L5MWns2MEbuk_qXQkW6QzP-HOqz09W-284q7-r8m55unCQSMQJ1ZSGYbB9LImZ6lrXnVUkOIj-61v5l9h1ep2cMUs79WdHu7yvFkliGt_fteN4oM3zh0dNP6ghjduqjM_yMdrmgnFxlMVR";

    // console log activity
    consoleLog("SRAccessToken", SRAccessToken);

    // react useState
    const [iframeVisible, setIframeVisible] = useState(false);

    const ChangeShinerankerFrame = (targetFrame) => {

        const iframeElement = document.getElementById("frame");

        if (targetFrame === "start") {

            setIframeVisible(true);
            ChangeShinerankerFrame("projectsdashboard");

        } else if (iframeElement) {

            const iframeWindow = getIframeWindow(iframeElement);

            // console log activity
            consoleLog("iframeWindow", iframeWindow);
            consoleLog("targetFrame", targetFrame);

            if (iframeWindow) {
                iframeWindow?.updateview(targetFrame);
            }
        }
    };

    const getIframeWindow = (iframeObject) => {
        let doc;
        if (iframeObject.contentWindow) {
            return iframeObject.contentWindow;
        }
        if (iframeObject.window) {
            return iframeObject.window;
        }
        if (!doc && iframeObject.contentDocument) {
            doc = iframeObject.contentDocument;
        }
        if (!doc && iframeObject.document) {
            doc = iframeObject.document;
        }
        if (doc && doc.defaultView) {
            return doc.defaultView;
        }
        if (doc && doc.parentWindow) {
            return doc.parentWindow;
        }
        return undefined;
    };

    return (
        <div>
            {/* Buttons for controlling the iframe view */}
            <button
                onClick={() => ChangeShinerankerFrame("start")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                Show Iframe
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("projectsdashboard")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                projectsdashboard
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("keywordsdashboard")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                keywordsdashboard
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("keywordsresearch")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                keywordsresearch
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("trafficchecker")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                trafficchecker
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("aivideo")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                aivideo
            </button>
            <button
                onClick={() => ChangeShinerankerFrame("contenteditor")}
                className="text-white bg-[#2f3c54] py-2 px-10 cursor-pointer rounded-full"
            >
                contenteditor
            </button>

            {/* Iframe that is shown or hidden based on state */}
            {iframeVisible && (
                <div id="ShinerankerMain">
                    <iframe
                        id="frame"
                        style={{
                            width: "100%",
                            height: "1800px",
                            maxWidth: "1800px",
                            border: "2px",
                            borderStyle: "revert",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        frameBorder="1"
                        src={`https://app.shineranker.com/iframe/dashboard?token=${SRAccessToken}`}
                        title="ShineRanker SEO Audit"
                    />
                </div>
            )}
        </div>
    );
};

export default SRPage;