import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// use context
import UserContext from "../../components/UserContext";

// components
import { fetchAIChat, fetchAIChatSessions, fetchAdvanceConfigs } from "../../API/chatAPI";
import SideMenu from "../../components/ChatAI/SideMenu";
import ChatBox from "../../components/ChatAI/ChatBox";
import ChatInput from "../../components/ChatAI/ChatInput";

// functions
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import { getItemWithExpiry, setItemWithExpiry } from "../../functions/itemWithExpiry";
import { acceptedStatus, allAccess } from "../../Configs/planData";

const ChatAI = () => {

    // context method
    const userData = useContext(UserContext);

    // use location get the session ID
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const session = queryParams.get('session');

    // use state
    const [AIChatSession, setAIChatSession] = useState([]);
    const [advanceConfigs, setAdvanceConfigs] = useState([]);
    const [AIChat, setAIChat] = useState([]);
    const [currentSessionID, setCurrentSessionID] = useState("");
    const [isMobile, setIsMobile] = useState();
    const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
    const [autoScroll, setAutoScroll] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [isAllFetched, setIsAllFetched] = useState(false);
    const [chatPage, setChatPage] = useState(2);
    const [sending, setSending] = useState(false);

    // console log activity
    // consoleLog("userData", userData);

    // Text arrea input hight adjust function
    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    const handleSideMenu = () => {
        setIsOpenSideMenu(!isOpenSideMenu);
    };

    // Get advance option configs
    useEffect(() => {
        const getConfigs = async () => {

            const cachedSessionHistory = getItemWithExpiry('advanceConfigs');

            if (cachedSessionHistory) {
                // If cached data is available, use it
                setAdvanceConfigs(cachedSessionHistory);
            } else {
                // If no cached data, fetch from server
                const response = await fetchAdvanceConfigs(userData?.userData?.UserInfo?.email);
                setAdvanceConfigs(response?.data);
                consoleLog("pages > shinefy > ChatAI.jsx > getConfigs > response", response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry('advanceConfigs', response?.data, 3600000);
            }

        };
        getConfigs();
    }, []);

    // refetch advance config
    const refetchAdvanceConfig = async () => {

        await fetchAdvanceConfigs(userData?.userData?.UserInfo?.email)
            .then((response) => {

                setAdvanceConfigs(response?.data);
                consoleLog("pages > shinefy > ChatAI.jsx > refetchAdvanceConfig > response", response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry('advanceConfigs', response?.data, 3600000);
            }).catch((error) => {
                ToastEffect("error", "Something went wrong! #refetchAdvanceConfig");
                consoleLog("pages > shinefy > ChatAI.jsx > refetchAdvanceConfig > error", error);
            });
    };

    // Get AI chat session with "Efficient Data Fetching" method.
    useEffect(() => {
        const getAIChatSessions = async () => {
            // Try to get session history from local storage first
            const cachedSessionHistory = getItemWithExpiry('AIChatSession');

            if (cachedSessionHistory) {
                // If cached data is available, use it
                setAIChatSession(cachedSessionHistory);
            } else {
                // If no cached data, fetch from server
                const response = await fetchAIChatSessions(userData?.userData?.UserInfo?.email);
                setAIChatSession(response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry('AIChatSession', response?.data, 3600000);
            }
        };

        getAIChatSessions();
    }, []);

    // Handle session and push to URL as ?session=<Session ID> 
    const handleChatAISession = (sessionID) => {
        window.history.pushState("Session", "Title", `?session=${sessionID}`);
        setCurrentSessionID(sessionID);
        setIsAllFetched(false);
        setChatPage(2);
        // consoleLog("pages > shinefy > handleChatAISession", sessionID);
    };

    // Get conversations base from sessionID
    useEffect(() => {

        const sessionID = currentSessionID ? currentSessionID : session;

        if (!userData || !sessionID) {
            return;
        }

        const getAIChat = async () => {
            // Try to get chat history from local storage first
            const cachedChatHistory = getItemWithExpiry(sessionID);

            if (cachedChatHistory) {
                // If cached data is available, use it
                setAIChat(cachedChatHistory);
            } else {
                // If no cached data, fetch from server
                const response = await fetchAIChat(userData?.userData?.UserInfo?.email, sessionID, 1);
                setAIChat(response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry(sessionID, response?.data, 3600000);
            }
        };
        getAIChat();
    }, [currentSessionID, userData, currentSessionID, session]);

    // re-fetch chat session from the server
    const refetchAIChatSession = async () => {
        await fetchAIChatSessions(userData?.userData?.UserInfo?.email)
            .then((response) => {
                setAIChatSession(response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry('AIChatSession', response?.data, 3600000);
            }).catch((error) => {
                ToastEffect("error", "Something went wrong! #refetchAIChatSession");
                consoleLog("pages > ChatAI.jsx > refetchAIChatSession > error", error);
            });
    };

    // re-fetch chats from the server
    const refetchAIChats = async () => {

        const sessionID = currentSessionID ? currentSessionID : session;

        if (!userData || !sessionID) {
            return;
        }


        await fetchAIChat(userData?.userData?.UserInfo?.email, sessionID, 1)
            .then((response) => {

                setAIChat(response?.data);
                consoleLog("pages > shinefy > ChatAI.jsx > refetchAIChats > response", response?.data);

                // Store the fetched data in local storage with a 1-hour expiry time
                setItemWithExpiry(sessionID, response?.data, 3600000);
            }).catch((error) => {
                ToastEffect("error", "Something went wrong! #refetchAIChats");
                consoleLog("pages > ChatAI.jsx > refetchAIChats > error", error);
            });
    };

    // chat scroll to the bottom of the message functions
    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleScroll = (e) => {
        const element = e.target;
        setIsVisible(element.scrollHeight - element.scrollTop >= element.clientHeight);
    };

    // to avoid lagging during scroll over flow using dedbounce
    const debouncedHandleScroll = debounce(handleScroll, 100);

    return (
        <div className="flex h-screen antialiased text-gray-800">
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                {isOpenSideMenu && (
                    <SideMenu
                        refetchAdvanceConfig={refetchAdvanceConfig}
                        advanceConfigs={advanceConfigs}
                        userData={userData}
                        refetchAIChats={refetchAIChats}
                        refetchAIChatSession={refetchAIChatSession}
                        sessionID={currentSessionID ? currentSessionID : session}
                        handleChatAISession={handleChatAISession}
                        handleSideMenu={handleSideMenu}
                        isMobile={isMobile}
                        AIChatSession={AIChatSession}
                        setCurrentSessionID={setCurrentSessionID}
                        setAIChat={setAIChat}
                    />
                )}
                <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
                    <main className="relative h-full w-full flex-1 overflow-auto transition-width">

                        {/* <div className="fixed left-0 top-1/2 z-40" style={{ transform: 'translateX(260px) translateY(-50%) rotate(0deg) translateZ(0px)' }}>
                            <button>
                                <span className="" data-state="closed">
                                    <div className="flex h-[72px] w-8 items-center justify-center">
                                        <div className="flex h-6 w-6 flex-col items-center">
                                            <div className="h-3 w-1 rounded-full" style={{ background: 'var(--text-quaternary)', transform: 'translateY(0.15rem) rotate(0deg) translateZ(0px)' }} />
                                            <div className="h-3 w-1 rounded-full" style={{ background: 'var(--text-quaternary)', transform: 'translateY(-0.15rem) rotate(0deg) translateZ(0px)' }} />
                                        </div>
                                    </div>
                                    <span style={{ position: 'absolute', border: '0px', width: '1px', height: '1px', padding: '0px', margin: '-1px', overflow: 'hidden', clip: 'rect(0px, 0px, 0px, 0px)', whiteSpace: 'nowrap', overflowWrap: 'normal' }}>
                                        Clos sidebar
                                    </span>
                                </span>
                            </button>

                        </div> */}

                        <div role="presentation" className="flex h-full flex-col">

                            {/* chat box */}
                            <ChatBox
                                sending={sending}
                                debouncedHandleScroll={debouncedHandleScroll}
                                autoScroll={autoScroll}
                                handleSideMenu={handleSideMenu}
                                isMobile={isMobile}
                                AIChat={AIChat}
                                userData={userData}
                                sessionID={currentSessionID ? currentSessionID : session}
                                setAIChat={setAIChat}
                                isAllFetched={isAllFetched}
                                setIsAllFetched={setIsAllFetched}
                                chatPage={chatPage}
                                setChatPage={setChatPage}
                            />

                            {/* input */}
                            {acceptedStatus.includes(userData?.subscriptionData?.message) && (
                                <ChatInput
                                    setChatPage={setChatPage}
                                    setIsAllFetched={setIsAllFetched}
                                    isVisible={isVisible}
                                    setCurrentSessionID={setCurrentSessionID}
                                    setAIChat={setAIChat}
                                    refetchAIChats={refetchAIChats}
                                    refetchAIChatSession={refetchAIChatSession}
                                    userData={userData}
                                    sessionID={currentSessionID ? currentSessionID : session}
                                    setAutoScroll={setAutoScroll}
                                    sending={sending}
                                    setSending={setSending}
                                />
                            )}

                        </div>

                        {/* <div className="group fixed bottom-3 right-3 z-10 hidden gap-1 lg:flex">
                            <div className="group relative" data-headlessui-state="">
                                <button
                                    className="flex items-center justify-center rounded-full border border-token-border-light text-token-text-secondary"
                                    id="headlessui-menu-button-:ruq:"
                                    type="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                >
                                    <div className="flex h-6 w-6 items-center justify-center text-xs">?</div>
                                </button>
                            </div>
                        </div> */}

                    </main>
                </div>
            </div>
        </div>
    );
};

export default ChatAI;