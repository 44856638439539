// libraries
import React, { useCallback, useEffect, useRef, useState } from "react";

// custom components
import { removeSession, updateSessionTitle } from "../../API/chatAPI";
import AdvanceOption from "./AdvanceOption";
import CustomPrompts from "./CustomPrompts";
import { acceptedStatus } from "../../Configs/planData";

const SideMenu = (props) => {

    // Props
    const {
        refetchAdvanceConfig,
        advanceConfigs,
        userData,
        refetchAIChats,
        refetchAIChatSession,
        handleSideMenu,
        isMobile,
        AIChatSession,
        handleChatAISession,
        sessionID,
        setCurrentSessionID,
        setAIChat
    } = props;

    // console.log(userData);

    // use state
    const [showAdvanceOption, setShowAdvanceOption] = useState(false);
    const [showCustomPrompt, setShowCustomPrompt] = useState(false);
    const [editableContent, setEditableContent] = useState({});
    const [editingIndex, setEditingIndex] = useState(null);
    const [removingSession, setRemovingSession] = useState(false);

    // Toggle advance option
    const handleAdvanceOption = () => {
        setShowAdvanceOption(!showAdvanceOption);
        if (showCustomPrompt) {
            setShowCustomPrompt(false);
        }
    };

    // Toggle Custom Prompt
    const handleCustomPrompt = () => {
        setShowCustomPrompt(!showCustomPrompt);
        if (showAdvanceOption) {
            setShowAdvanceOption(false);
        }
    };

    // handle title edit
    const handleEdit = (idx) => {
        setEditingIndex(prevIdx => prevIdx === idx ? null : idx);
    };

    // handle remove session
    const handleRemove = async (idx, email, session) => {
        // console.log("email", email);
        // console.log("session", session);
        if (editingIndex !== idx) {
            setRemovingSession(true);
            await removeSession(email, session);
            refetchAIChatSession();
            refetchAIChats();
            setRemovingSession(false);
        } else {
            setEditingIndex(null);
        }
    };

    // focus on editable content when edit button is clicked
    const editableRef = useCallback(node => {
        if (node !== null && editingIndex !== null) {
            node.focus();
        }
    }, [editingIndex]);

    // handle key event when enter is hit
    const handleKeyPress = async (e, email, idx) => {
        if (e.key === "Enter") {
            e.preventDefault();  // Prevents the default action of Enter key
            setEditingIndex(null);
            await updateSessionTitle(email, sessionID, editableContent[idx]);
            refetchAIChatSession();
            // console.log('Captured content on Enter:', editableContent[idx]);
        }
    };

    // handle content editable value
    const handleBlur = async (e, email) => {
        const newContent = e.currentTarget.textContent;
        // console.log(newContent);
        await updateSessionTitle(email, sessionID, newContent);
        refetchAIChatSession();
    };

    const handleInput = (e, idx) => {
        const newContent = e.target.textContent;
        setEditableContent(prevContent => ({ ...prevContent, [idx]: newContent, }));
    };

    const newChat = () => {
        // window.location.href = "/ai_chat";
        setCurrentSessionID("");
        window.history.pushState("Session", "Title", `/ai_chat`);
        setAIChat([]);
    };

    return (
        <div className={`flex bg-gray-100/30 flex-col py-8 pl-5 pr-4 w-64 flex-shrink-0 ${isMobile && "fixed top-10 right-0.5 bg-gray-600 p-4 rounded shadow-lg z-50"}`}>
            {isMobile && (
                <button onClick={handleSideMenu} className="absolute top-2 left-3 text-lg px-2 text-gray-100 hover:text-gray-200">
                    {/* <i className="fa-solid fa-circle-xmark" /> */}
                    <i className="fa-solid fa-xmark" />
                </button>
            )}
            <div className="flex flex-row items-center justify-center h-12 w-full">
                <div className="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10">
                    <i className="fa-solid fa-comments text-xl" />
                </div>
                <div className="ml-2 font-bold text-2xl text-gray-100">AI-Chat</div>
            </div>

            {/* <div className="flex flex-col items-center bg-indigo-100 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg">
                <div className="h-20 w-20 rounded-full border overflow-hidden">
                    <img
                        src={shinefy_img}
                        alt="Avatar"
                        className="h-full w-full"
                    />
                </div>
                <div className="text-sm font-semibold mt-2">Aminos Co.</div>
                <div className="text-xs text-gray-500">Lead UI/UX Designer</div>
                <div className="flex flex-row items-center mt-3">
                    <div
                        className="flex flex-col justify-center h-4 w-8 bg-indigo-500 rounded-full"
                    >
                        <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
                    </div>
                    <div className="leading-none ml-1 text-xs">Active</div>
                </div>
            </div> */}

            <div className={`flex flex-col flex-grow overflow-hidden mt-8 ${isMobile && "max-h-72"}`}>
                <div className="flex flex-row items-center justify-between text-xs">
                    <span className="font-bold text-gray-100 text-base">Active Conversations</span>
                    {/* <small className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full p-[0.58rem]">4</small> */}
                    {!!AIChatSession?.length && (
                        <div className="border border-gray-100 bg-gray-100 rounded-full px-1 font-semibold">
                            {AIChatSession?.length}
                        </div>
                    )}
                </div>
                <div className="flex flex-col space-y-1 mt-4 -mx-0 h-auto overflow-y-auto text-gray-100">
                    <button onClick={newChat} className="flex flex-row items-center border-2 rounded-md p-2 hover:bg-gray-200/10">
                        {/* <div className="flex items-center justify-center h-8 w-8 text-2xl rounded-full">
                            <i className="fa-solid fa-comment" />
                        </div> */}
                        <p className="ml-2 text-lg font-semibold">Create New Chat</p>
                        <div className="flex items-center justify-center ml-auto h-8 w-8 text-2xl text-gray-100 leading-none">
                            <i className="fa-solid fa-pen-to-square" />
                        </div>
                    </button>

                    {AIChatSession?.map((data, idx) => {
                        return (
                            <button
                                onClick={() => handleChatAISession(data?.session)}
                                key={idx}
                                className={`flex flex-row items-center ${sessionID === data?.session && "bg-gray-100 text-gray-600"} hover:bg-gray-100 hover:text-gray-600 rounded-xl p-2`}
                            >
                                <div className="flex items-center justify-center h-8 w-8 text-2xl rounded-full">
                                    <i className="fa-solid fa-comment" />
                                </div>
                                <p
                                    ref={editableRef}
                                    title={data?.title}
                                    onInput={(e) => handleInput(e, idx)}
                                    onKeyDown={(e) => handleKeyPress(e, data?.email, idx)}
                                    onBlur={(e) => handleBlur(e, data?.email)}
                                    className={`ml-2 text-sm font-semibold ${editingIndex !== idx && "truncate"}`}
                                    contentEditable={editingIndex === idx}
                                    suppressContentEditableWarning={true}
                                >
                                    {data?.title}
                                </p>

                                {sessionID === data?.session && (
                                    <>
                                        <div
                                            onClick={() => handleEdit(idx)}
                                            className="flex items-center justify-center ml-auto text-xs h-4 w-4 text-green-500 hover:text-green-400 rounded leading-none"
                                        >
                                            <i title={editingIndex === idx ? "save" : "edit"} className={`fa-solid ${editingIndex === idx ? "fa-check text-base" : "fa-pen-to-square text-base"} `} />
                                        </div>
                                        <div
                                            onClick={() => handleRemove(idx, data?.email, data?.session)}
                                            className="flex items-center justify-center ml-1 text-xs h-4 w-4 text-red-500 hover:text-red-400 rounded leading-none"
                                        >
                                            <i title={editingIndex === idx ? "cancel" : "delete"} className={`fa-solid ${editingIndex === idx ? "fa-xmark text-base" : removingSession ? "fa-spinner fa-spin-pulse" : "fa-trash text-base"} `} />
                                        </div>
                                    </>
                                )}

                            </button>
                        );
                    })}

                </div>
                <div className="flex flex-col space-y-1 mt-4 -mx-2 overflow-y-auto flex-grow" />
            </div>

            <div className="flex flex-col mt-5 pb-0">

                {/* <div className="flex flex-row items-center justify-between text-xs">
                    <span className="font-bold text-gray-100 text-base">Advance Settings</span>
                </div> */}

                <div className="boder border-b-[1.5px] border-r-gray-100 mb-2" />

                <button onClick={handleAdvanceOption} className={`flex mt-3 py-3 px-3 items-center gap-3 rounded-md ${showAdvanceOption ? "bg-gray-100 text-gray-600" : "bg-gray-500 text-white"} hover:bg-gray-100 transition-colors duration-200 hover:text-gray-600 text-sm`}>
                    <i className="fa-solid fa-bars" />Advance Option
                    <div className="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative">
                    </div>
                    <i className={`fa-solid fa-angle-${showAdvanceOption ? "down" : "up"}`} />
                </button>

                {/* {showAdvanceOption && (!userData?.subscriptionData?.message === "noSubscription" || userData?.subscriptionData?.status) && (
                    <AdvanceOption
                        refetchAdvanceConfig={refetchAdvanceConfig}
                        userData={userData}
                        advanceConfigs={advanceConfigs}
                    />
                )} */}

                {/* {showAdvanceOption && (userData?.subscriptionData?.message === "freeAccess" || (!userData?.subscriptionData?.message === "noSubscription" || userData?.subscriptionData?.status)) && ( */}
                {showAdvanceOption && acceptedStatus.includes(userData?.subscriptionData?.message) && (
                    <AdvanceOption
                        refetchAdvanceConfig={refetchAdvanceConfig}
                        userData={userData}
                        advanceConfigs={advanceConfigs}
                    />
                )}

                <button onClick={handleCustomPrompt} className={`flex mt-3 py-3 px-3 items-center gap-3 rounded-md ${showCustomPrompt ? "bg-gray-100 text-gray-600" : "bg-gray-500 text-white"} hover:bg-gray-100 transition-colors duration-200 hover:text-gray-600 text-sm`}>
                    <i className="fa-solid fa-feather" />Custom Prompt
                    <div className="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative">
                    </div>
                    <i className={`fa-solid fa-angle-${showCustomPrompt ? "down" : "up"}`} />
                </button>

                {/* {showCustomPrompt && (!userData?.subscriptionData?.message === "noSubscription" || userData?.subscriptionData?.status) && (
                    <CustomPrompts
                        refetchAdvanceConfig={refetchAdvanceConfig}
                        userData={userData}
                        advanceConfigs={advanceConfigs}
                    />
                )} */}

                {/* {showCustomPrompt && (userData?.subscriptionData?.message === "freeAccess" || (!userData?.subscriptionData?.message === "noSubscription" || userData?.subscriptionData?.status)) && ( */}
                {showCustomPrompt && acceptedStatus.includes(userData?.subscriptionData?.message) && (
                    <CustomPrompts
                        refetchAdvanceConfig={refetchAdvanceConfig}
                        userData={userData}
                        advanceConfigs={advanceConfigs}
                    />
                )}

            </div>
        </div>
    );
};

export default SideMenu;