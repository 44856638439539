import React, { useEffect, useRef, useState } from "react";
import lottie from "lottie-web";

// google captcha imports
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// components and helpers
import LogoAndTitle from "../../components/LogoAndTitle";
import ResponseMessage from "../../components/ResponseMessage";
import consoleLog from "../../functions/consoleLog";
import { registerUser, verifyCaptcha } from "../../API/authAPI";
import { ToastEffect } from "../../functions/ToastEffect";

// Images && Logo
import ShineRanker from "../../constant/images/ShineRanker.png";
import { Tooltip } from "react-tooltip";
import RegisterInput from "../../components/Register/RegisterInput";


export default function Register() {

    let animationContainer = useRef();

    // google captcha
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [is_active, setIsActive] = useState("0");
    const [role, setRole] = useState("2001");
    const [user_confirm_email, setUserConfirmEmail] = useState("");
    const [user_confirm_pass, setUserConfirmPass] = useState("");
    const [errorMessages, setErrorMessages] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [signUpNewsLetter, setSignUpNewsLetter] = useState(false);

    useEffect(() => {
        const heroAnimation = lottie.loadAnimation({
            container: animationContainer.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shinefy-flying-robot.json",
        });

        return () => {
            heroAnimation.destroy();
        };
    }, [animationContainer]);

    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "username":
                setUsername(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "first_name":
                setFirstName(value);
                break;
            case "last_name":
                setLastName(value);
                break;
            case "is_active":
                setIsActive(value);
                break;
            case "role":
                setRole(value);
                break;
            case "user_confirm_email":
                setUserConfirmEmail(value);
                break;
            case "user_confirm_pass":
                setUserConfirmPass(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitting(true);

        // Clear previous response messages
        setResponseMessage([]);

        // Validate form inputs
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrorMessages(validationErrors);
            setIsSubmitting(false);
            return;
        }

        // Clear previous error messages
        setErrorMessages({});

        if (executeRecaptcha) {
            const token = await executeRecaptcha("register");
            const captchaResponse = await verifyCaptcha(token);
            if (!captchaResponse?.data?.success === true) {
                setResponseMessage([{ kind: "error", message: captchaResponse?.data?.error }]);
                setIsSubmitting(false);
                return;
            }
        }

        const url = `${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-account`;
        const fixedUsername = username.toLowerCase();
        const fixedEmail = email.toLowerCase();

        const userData = {
            username: fixedUsername,
            email: fixedEmail,
            password,
            first_name,
            last_name,
            is_active,
            role,
            url,
            user_confirm_email,
            user_confirm_pass,
            signUpNewsLetter
        };

        try {
            const response = await registerUser(userData);
            setIsSubmitting(false);
            // Check if the error is a duplicate key response
            if (response?.data?.message.includes("E11000 duplicate key")) {
                setIsSubmitting(false);
                setResponseMessage([{ kind: "error", message: "Username or Email already exists." }]);
            } else if (response?.response?.data?.error) {
                setIsSubmitting(false);
                setResponseMessage([{ kind: "error", message: response?.response?.data?.error?.username }]);
            } else {
                // Handle other errors here
                setIsSubmitting(false);
                setResponseMessage([{ kind: "success", message: "Registration successful, please check your email to activate your account." }]);
            }
            consoleLog("pages > Auth > Register.js > handleSubmit > response", response);
        } catch (error) {
            ToastEffect("error", "Something went wrong!");
            consoleLog("pages > Auth > Register.js > handleSubmit > error", error);
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!username) {
            errors.username = "Username is required.";
        }

        if (!email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(email)) {
            errors.email = "Invalid email format.";
        }

        // if (!user_confirm_email) {
        //   errors.user_confirm_email = "Confirm Email is required.";
        // } else if (user_confirm_email !== email) {
        //   errors.user_confirm_email = "Email and Confirm Email must match.";
        // }

        if (!password) {
            errors.password = "Password is required.";
        } else if (!isValidPassword(password)) {
            errors.password = "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.";
        }

        // if (!user_confirm_pass) {
        //   errors.user_confirm_pass = "Confirm Password is required.";
        // } else if (user_confirm_pass !== password) {
        //   errors.user_confirm_pass = "Password and Confirm Password must match.";
        // }

        if (!first_name) {
            errors.first_name = "First name is required.";
        }

        if (!last_name) {
            errors.last_name = "Last name is required.";
        }


        return errors;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const isValidPassword = (password) => {
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleReadMore = () => {
        window.location.href = process.env.REACT_APP_SHINEFY_FRONT_PAGE;
    };

    const handleLoginPage = () => {
        window.location.href = process.env.REACT_APP_SHINEFY_CLIENT;
    };

    // signup for new signUpForNewsLetter
    const signUpForNewsLetter = (data) => {
        setSignUpNewsLetter(data);
    };

    return (
        <div className="h-screen md:flex">

            <div
                style={{ backgroundImage: 'url(/assets/images/cover.png)' }}
                className="relative overflow-hidden md:flex w-1/2 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center hidden"
            >
                <div className="relative z-10">
                    <h1 className="text-white font-bold text-5xl font-sans">Shinefy</h1>
                    <p className="text-white mt-1 text-lg">The most popular collection of powerful AI tools</p>
                    <div className="flex space-x-1">
                        <button onClick={handleReadMore} type="submit" className="block w-28 bg-white hover:bg-blue-200 text-indigo-800 mt-3 py-2 rounded-2xl font-bold mb-2">
                            Read More
                        </button>
                        {/* <button onClick={handleLoginPage} type="submit" className="block w-[4.5rem] bg-white hover:bg-blue-200 text-indigo-800 mt-3 py-2 rounded-2xl font-bold mb-2">
              Login
            </button> */}
                    </div>
                </div>

                <div className="absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                <div className="absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                <div className="absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                <div className="absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
            </div>

            {/* <!-- The other half of the screen --> */}
            <div className="flex md:w-1/2 h-screen justify-center py-10 items-center bg-white dark:bg-gray-800">

                {responseMessage.length > 0 && (
                    <div
                        role="tooltip"
                        className={`absolute z-10 max-w-lg ${isMobile ? "top-[4%]" : "top-[15%]"} transform translate-y-0 inline-block px-3 py-2 text-sm font-medium text-red-500`}
                    >
                        {errorMessages.first_name}
                        <ResponseMessage
                            responseMessage={responseMessage}
                            closeResponseMessage={closeResponseMessage}
                        />
                        {/* <div className="tooltip-arrow" data-popper-arrow /> */}
                    </div>
                )}

                <form onSubmit={handleSubmit}>

                    <h1 className="text-gray-800 font-bold text-2xl mb-1 dark:text-gray-200">Hello new user!</h1>
                    <p className="text-sm font-normal text-gray-500 mb-7">Welcome to shinefy</p>

                    {/* <div className={`relative ${!errorMessages.first_name ? "border-red-500" : ""} flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50`}>
            <i className="fa-solid fa-user mr-1" />
            <input
              type="text"
              name="first_name"
              placeholder="First name"
              value={first_name}
              onChange={handleChange}
              className="pl-2 outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-gray-200 dark:bg-gray-800 w-full"
            />
            {!errorMessages.first_name && (
              <div role="tooltip" className="absolute z-50 -top-10 left-0 transform translate-y-0 inline-block px-3 py-2 text-sm font-medium text-red-500 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-600">
                {errorMessages.first_name}Test error
                <div className="tooltip-arrow" data-popper-arrow />
              </div>
            )}
          </div> */}

                    <RegisterInput
                        errorMessages={errorMessages?.first_name}
                        label={`<p class="break-normal text-red-500 font-bold">${errorMessages?.first_name}</p>`}
                        data={first_name}
                        handleChange={handleChange}
                        name="first_name"
                        placeholder="First name"
                        type="text"
                        icon="fa-solid fa-user"
                    />

                    <RegisterInput
                        errorMessages={errorMessages?.last_name}
                        label={`<p class="break-normal text-red-500 font-bold">${errorMessages?.last_name}</p>`}
                        data={last_name}
                        handleChange={handleChange}
                        name="last_name"
                        placeholder="Last name"
                        type="text"
                        icon="fa-solid fa-feather"
                    />

                    <RegisterInput
                        errorMessages={errorMessages?.username}
                        label={`<p class="break-normal text-red-500 font-bold">${errorMessages?.username}</p>`}
                        data={username}
                        handleChange={handleChange}
                        name="username"
                        placeholder="Username"
                        type="text"
                        icon="fa-solid fa-fingerprint"
                    />

                    <RegisterInput
                        errorMessages={errorMessages?.email}
                        label={`<p class="break-normal text-red-500 font-bold">${errorMessages?.email}</p>`}
                        data={email}
                        handleChange={handleChange}
                        name="email"
                        placeholder="Email"
                        type="text"
                        icon="fa-solid fa-at"
                    />

                    <RegisterInput
                        errorMessages={errorMessages?.password}
                        label={`<p class="break-normal text-red-500 font-bold">${errorMessages?.password}</p>`}
                        data={password}
                        handleChange={handleChange}
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        icon="fa-solid fa-key"
                        togglePasswordVisibility={togglePasswordVisibility}
                        showPassword={showPassword}
                        kind="password"
                    />

                    {/* <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50">
            <i className="fa-solid fa-feather" />
            <input
              className="pl-2 outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-gray-200 dark:bg-gray-800"
              type="text"
              placeholder="Last name"
            />
          </div> */}

                    {/* <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50">
            <i className="fa-solid fa-fingerprint" />
            <input
              className="pl-2 outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-gray-200 dark:bg-gray-800"
              type="text"
              placeholder="Username"
            />
          </div> */}

                    {/* <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50">
            <i className="fa-solid fa-at" />
            <input
              className="pl-2 outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-gray-200 dark:bg-gray-800"
              type="text"
              placeholder="Email"
            />
          </div> */}

                    {/* <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 text-gray-800 dark:text-gray-50">
            <i className="fa-solid fa-key" />
            <input
              className="pl-2 outline-none border-none focus:border-none focus:outline-none focus:ring-0 bg-gray-200 dark:bg-gray-800"
              type="text"
              placeholder="Password"
            />
          </div> */}

                    {/* <div className="mt-2">
            {responseMessage.length > 0 && (
              <ResponseMessage
                responseMessage={responseMessage}
                closeResponseMessage={closeResponseMessage}
              />
            )}
          </div> */}

                    <button
                        type="submit"
                        className="relative flex w-full justify-center items-center bg-indigo-600 hover:bg-indigo-500 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
                    >
                        {isSubmitting && (
                            <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4 fa-lg" />
                        )}
                        Register
                    </button>


                    <div className="flex items-center justify-center my-4">
                        <div className="flex items-center h-5">
                            <input
                                checked={signUpNewsLetter}
                                onChange={(e) => signUpForNewsLetter(e.target.checked)}
                                id="remember" aria-describedby="remember" type="checkbox"
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-700 focus:ring-3 focus:ring-blue-300"
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label
                                data-tooltip-id="tooltip-register-newsletter"
                                // data-tooltip-content="Subscribe to receive the latest updates."
                                data-tooltip-html='<p class="font-semibold text-base">Subscribe to receive latest updates.</p>'
                                data-tooltip-place="bottom"
                                htmlFor="remember"
                                className="dark:text-gray-300"
                            >
                                Subscribe for our news letter
                                <Tooltip id="tooltip-register-newsletter" />
                            </label>
                        </div>

                        <a href="/forgot_password" className="text-sm font-medium text-blue-500 hover:underline ml-5">Forgot password?</a>
                    </div>


                    {/* <a href="/forgot_password" className="text-sm ml-2 text-gray-200 hover:text-blue-500 cursor-pointer">Forgot Password ?</a> */}

                    {/* <div className="my-1 border-b text-center">
            <div
              className="leading-none px-2 inline-block text-sm text-gray-600 dark:text-gray-300 tracking-wide font-medium bg-white dark:bg-gray-800/90 transform translate-y-1/2">
              Or sign in
            </div>
          </div> */}

                </form>
            </div >
        </div >
    );
}
