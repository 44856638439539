import React, { useState } from "react";

// google captcha imports
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

// components and helpers
import { forgotPass, verifyCaptcha } from "../../API/authAPI";
import consoleLog from "../../functions/consoleLog";
import { ToastEffect } from "../../functions/ToastEffect";
import ResponseMessage from "../../components/ResponseMessage";
import LogoAndTitle from "../../components/LogoAndTitle";
import lottie from "lottie-web";
import { useEffect } from "react";
import { useRef } from "react";

const ForgotPassword = () => {

    // google captcha
    const { executeRecaptcha } = useGoogleReCaptcha();

    // use ref
    let resetPassRobot = useRef();

    const [email, setEmail] = useState("");
    const [responseMessage, setResponseMessage] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const resetPass = lottie.loadAnimation({
            container: resetPassRobot.current,
            rerender: "svg",
            loop: true,
            autoplay: true,
            path: "/assets/shinefy-floating-robot.json",
        });

        return () => {
            resetPass.destroy();
        };
    }, [resetPassRobot]);

    useEffect(() => {
        // Function to check if the screen is mobile
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 1023); // Adjust the threshold as per your requirement
        };

        // Check the screen size initially
        checkIsMobile();

        // Attach the event listener to window resize
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const closeResponseMessage = () => {
        setResponseMessage([]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitting(true);

        // convert email to lower case
        const fixedEmail = email.toLowerCase();

        // Clear previous response messages
        setResponseMessage([]);

        // check if field is empty
        if (!email) {
            setResponseMessage([{ kind: "error", message: "Email is required." }]);
            setIsSubmitting(false);
            return;
        }

        if (executeRecaptcha) {
            const token = await executeRecaptcha("forgotPassword");
            const captchaResponse = await verifyCaptcha(token);
            if (!captchaResponse?.data?.success === true) {
                setResponseMessage([{ kind: "error", message: captchaResponse?.data?.error }]);
                setIsSubmitting(false);
                return;
            }
        }

        await forgotPass(fixedEmail, `${process.env.REACT_APP_SHINEFY_CLIENT}/reset_password`)
            .then((response) => {
                setIsSubmitting(false);
                setResponseMessage([{
                    kind: "success",
                    message: "We've emailed you instructions to reset your password. Please check your inbox and spam folder. You should receive the email shortly!"
                }]);
                consoleLog("pages > Auth > Forgotpassword.js > handleSubmit > response", response);
            }).catch((err) => {
                setIsSubmitting(false);
                ToastEffect("error", "Something went wrong!");
                consoleLog("pages > Auth > Forgotpassword.js > handleSubmit > error", err);
            });
    };

    return (
        <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12 bg-gradient-to-tr from-blue-800 to-purple-950">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div
                    style={{ backgroundImage: 'url(/assets/images/cover.png)' }}
                    className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-90 sm:rounded-3xl"
                />
                <div className="relative bottom-32 w-auto h-[20rem]" ref={resetPassRobot} />
                <div className="relative px-4 py-10 bottom-40 bg-white dark:bg-gray-800 shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-md mx-auto">
                        <div className="dark:text-gray-300">
                            <h1 className="text-2xl font-semibold">Forgot shinefy password</h1>
                        </div>
                        <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">

                                <form onSubmit={handleSubmit}>
                                    <div className="relative">
                                        <input
                                            onChange={handleChange}
                                            type="text"
                                            autoComplete="off"
                                            id="email"
                                            name="email"
                                            className="peer placeholder-transparent h-10 w-full border-x-0 border-t-0 border-b-2 border-gray-300 text-gray-900 dark:text-gray-300 dark:bg-gray-800 outline-none focus:outline-none focus:ring-0 focus:borer-rose-600"
                                            placeholder="Email address"
                                        // required
                                        />
                                        <label htmlFor="email" className="absolute font-semibold left-0 -top-3.5 text-gray-600 dark:text-gray-300 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 dark:peer-focus:text-gray-400 peer-focus:text-gray-600 peer-focus:text-sm">
                                            Email address
                                        </label>
                                    </div>

                                    <div className="mt-3">
                                        <button
                                            disabled={isSubmitting ? true : false}
                                            type="submit"
                                            className="relative flex w-full justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            {isSubmitting && (
                                                <i className="fa-solid fa-spinner fa-spin-pulse absolute left-4" />
                                            )}
                                            Forgot Password
                                        </button>
                                        <GoogleReCaptcha action="resetPassword" />
                                    </div>

                                </form>

                                {/* <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300 dark:border-gray-500"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-gray-100 dark:bg-gray-800 px-2 text-gray-500 dark:text-gray-100">Or</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-center">
                  <a
                    href="/"
                    className="font-medium text-blue-500 hover:underline">
                    Sign in
                  </a>
                </div>
              </div> */}

                                <div className={`absolute ${isMobile ? "w-[20.5rem]" : "w-[22rem]"}  right-[8%]`}>
                                    {responseMessage.length > 0 && (
                                        <ResponseMessage
                                            responseMessage={responseMessage}
                                            closeResponseMessage={closeResponseMessage}
                                        />
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
