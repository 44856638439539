// libraries
import axios from "axios";
import debounce from "lodash.debounce";

// custom components
import { ToastEffect } from "../functions/ToastEffect";
import consoleLog from "../functions/consoleLog";

/** Forgot password */
export const forgotPass = async (email, url) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/forgot-password`, {
            email: email,
            url: url
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #forgotPass");
        consoleLog("API > authAPI.js > forgotPass", error);
        return error;
    }
};

/** Verify if reset key is valid */
export const verifyResetKey = async (email, key) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-reset-password`, {
            email: email,
            key: key
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifyResetKey");
        consoleLog("API > authAPI.js > verifyResetKey", error);
        return error;
    }
};

/** Reset password */
export const resetPass = async (email, password, confirmPassword) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/reset-password`, {
            email: email,
            password: password,
            confirmPassword: confirmPassword
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #resetPass");
        consoleLog("API > authAPI.js > resetPass", error);
        return error;
    }
};

/** login user account */
export const loginUser = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/login`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #loginUser");
        consoleLog("API > authAPI.js > loginUser", error);
        return error;
    }
};
// export const loginUser = async (email, password) => {
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/login`, {
//             email: email,
//             password: password
//         }, {
//             withCredentials: true // This allows cookies to be sent and received across domains
//         });
//         return response;
//     } catch (error) {
//         ToastEffect("error", "Something went wrong! #loginUser");
//         consoleLog("API > authAPI.js > loginUser", error);
//         return error;
//     }
// };

/** process payment "success" reponse */
export const processPaymentSuccessful = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/process/payment-success`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #processPaymentSuccessful");
        consoleLog("API > authAPI.js > processPaymentSuccessful", error);
        return error;
    }
};

/** process payment "success" reponse */
export const processPurchaseSuccessful = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/process/purchase-success`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #processPurchaseSuccessful");
        consoleLog("API > authAPI.js > processPurchaseSuccessful", error);
        return error;
    }
};

/** check autologin validation */
export const processAutologin = async (email, orderID, url, hash) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/auto-login`, {
            email: email,
            orderID: orderID,
            url: url,
            hash: hash,
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #processAutologin");
        consoleLog("API > authAPI.js > processAutologin", error);
        return error;
    }
};

/** Register user account */
export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/registration`, userData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #registerUser");
        consoleLog("API > authAPI.js > registerUser", error);
        return error;
    }
};

/** Verify captcha v3 */
export const verifyCaptcha = async (token) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-recaptcha`, {
            token: token
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifyCaptcha");
        consoleLog("API > authAPI.js > verifyCaptcha", error);
        return error;
    }
};

/** Verify access and refresh token */
export const verifyToken = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-token-dev`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifyToken");
        consoleLog("API > authAPI.js > verifyToken", error);
        return error;
    }
};

/** send required data to the server to verify stripe auto login validity */
export const verifyStripeAutoLogin = async (requiredData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-stripe-auto-login`, requiredData);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifyStripeAutoLogin");
        consoleLog("API > authAPI.js > verifyStripeAutoLogin", error);
        return error;
    }
};

/** verify captcha v2 */
export const verifyCaptchaV2 = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/verify-recaptcha-v2`, {
            token: token
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #verifyCaptchaV2");
        consoleLog("API > authAPI.js > verifyCaptchaV2", error);
        return error;
    }
};

/** connec discord and add VIP role */
export const connectDiscord = async (discordID, email, item_id) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/connect-discord`, {
            discordUserID: discordID,
            email: email,
            item_id: item_id
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #connectDiscord");
        consoleLog("API > authAPI.js > connectDiscord", error);
    }
};

/** pull data from shineranker SSO Auth */
export const getShifyUserData = async (access) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/auth_shineranker`, {
            access: access
        });
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getShineRankerUserData");
        consoleLog("API > authAPI.js > getShineRankerUserData", error);
    }
};

/** Sign up to news letter */
export const signUpToNewsLetter = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/subs/signup-newsletter`, data);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #signUpToNewsLetter");
        consoleLog("API > authAPI.js > signUpToNewsLetter", error);
    }
};

/** get server time before restart */
export const getServerRestartTime = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/timer-remaining/Tools_system_restart.timer`);
        return response;
    } catch (error) {
        ToastEffect("error", "Something went wrong! #getServerRestartTime");
        consoleLog("API > authAPI.js > getServerRestartTime", error);
    }
};

/** search guild channel */
export const createSRAccess = (data) => {
    return new Promise((resolve, reject) => {
        debouncedCreateSRAccess(data, resolve, reject);
    });
};

// debounced sub function
const debouncedCreateSRAccess = debounce(async (data, resolve, reject) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SHINEFY_SERVER}/auth/create-sr-access`, data);
        resolve(response);
    } catch (error) {
        consoleLog("debouncedCreateSRAccess", error);
        reject(error);
    }
}, 500);