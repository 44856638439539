// libraries
import axios from "axios";

// custom components
import { ToastEffect } from "../../../../functions/ToastEffect";
import consoleLog from "../../../../functions/consoleLog";

/**
 * Converts text to video by making a POST request to the specified API endpoint.
 *
 * @param {Object} data - The data for the text-to-video conversion.
 * @returns {Promise} A promise that resolves to the response data.
 * @throws {Error} If an error occurs during the text-to-video conversion process.
 */
export async function saveConfig(email, pathname, id, value) {

    let kind;

    if (pathname === "/general-purpose") {
        kind = process.env.REACT_APP_VIDEO_GEN_ID;
    } else {
        kind = process.env.REACT_APP_VIDEO_ENHANCER_ID;
    }

    const data = {
        user_email: email,
        user_auth: process.env.REACT_APP_AUTH,
        data_kind: kind,
        data_id: id,
        data_link: value?.value || value,
        data_content: value?.content || ""
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/save_config`, data);
        return response;
    } catch (error) {
        // throw error;
        consoleLog("api > configDBAPI.js > saveConfig", error);
        ToastEffect("error", "saveConfig Error!");
    }
}

export async function saveTaskId(email, kind, id, value) {
    const data = {
        user_email: email,
        user_auth: process.env.REACT_APP_AUTH,
        data_kind: kind,
        data_id: id,
        data_link: value,
        data_content: ""
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/save_taskId`, data);
        return response;
    } catch (error) {
        // throw error;
        consoleLog("api > configDBAPI.js > savetaskId", error);
        ToastEffect("error", "savetaskId Error!");
    }
}

export async function removeTaskId(email, kind, id, value) {
    const data = {
        user_email: email,
        user_auth: process.env.REACT_APP_AUTH,
        data_kind: kind,
        data_id: id,
        data_link: value,
        data_content: ""
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/remove_taskId`, data);
        return response;
    } catch (error) {
        // throw error;
        consoleLog("api > configDBAPI.js > removeTaskId", error);
        ToastEffect("error", "removeTaskId Error!");
    }
}

export const getConfig = async (email, pathname) => {

    // let kind;

    // if (pathname === "/general-purpose") {
    //     kind = process.env.REACT_APP_VIDEO_GEN_ID;
    // } else if (pathname === "/transcribe") {
    //     kind = process.env.REACT_APP_VIDEO_TRANSCRIBER_ID;
    // } else if (pathname === "/VG_GenerateAudio") {
    //     kind = process.env.REACT_APP_CUSTOM_AUDIO_ID;
    // } else if (pathname === "/VG_CreateVideo") {
    //     kind = process.env.REACT_APP_CUSTOM_VIDEO_ID;
    // } else if (pathname === "/VG_GenerateImage") {
    //     kind = process.env.REACT_APP_CUSTOM_IMAGE_ID;
    // } else if (pathname === "/videos") {
    //     kind = process.env.REACT_APP_CREATE_THUMBNAIL_ID;
    // } else {
    //     kind = process.env.REACT_APP_VIDEO_ENHANCER_ID;
    // }

    const data = {
        user_email: email,
        user_auth: process.env.REACT_APP_AUTH,
        data_id: "",
        data_kind: "", // data_kind: kind,
        data_link: "",
        data_content: ""
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/get_config`, data);
        return response;
    } catch (error) {
        // throw error;
        consoleLog("api > configDBAPI.js > getConfig", error);
        ToastEffect("error", "getConfig Error!");
    }
};
